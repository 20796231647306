import React from "react";
import styles from "./NyINorgeSearchPage.module.scss";
import HeroBlock, {
  HeroBlockProps,
} from "components/blocks/HeroBlock/HeroBlock";
import { ReactComponentPropsBase } from "src/base-props/ReactComponentPropsBase";
import SearchBlock, {
  SearchBlockProps,
} from "components/blocks/SearchBlock/SearchBlock";

export interface NyINorgeSearchPageProps extends ReactComponentPropsBase {
  hero?: HeroBlockProps;
  search?: SearchBlockProps;
}

const NyINorgeSearchPage: React.FC<NyINorgeSearchPageProps> = ({
  hero,
  search,
}) => {
  return (
    <main className={styles.nyINorgeSearchPage}>
      {hero && <HeroBlock {...hero} />}
      {search && <SearchBlock {...search} />}
    </main>
  );
};

export default NyINorgeSearchPage;
