import Image, { ImageProps } from "components/base/Image/Image";
import { ContentAreaProps } from "components/utils/ContentArea/ContentArea";
import { IconMS, RichTextBlock } from "SiteComponents";
import { LinkProps } from "src/base-props/LinkProps";
import styles from "./ContactBlockItem.module.scss";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "src/utils/useWindowSize";

export interface ContactBlockItemProps {
  className?: string;
  name: string;
  subHeading?: string;
  image?: ImageProps;
  ingress?: string;
  email?: string;
  phoneNumber?: string;
  link?: LinkProps;
  small?: boolean;
  horizontal?: boolean;
  text?: ContentAreaProps;
  showMoreText?: string;
  showLessText?: string;
  truncateText: boolean
}

const ContactBlockItem = ({
  className,
  name,
  subHeading,
  image,
  ingress,
  email,
  phoneNumber,
  link,
  text,
  small,
  horizontal,
  showLessText,
  showMoreText,
  truncateText
}: ContactBlockItemProps) => {
  const richTextRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<number>(0); // Start with 0 height
  const [showButton, setShowButton] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(!truncateText);

  const { width } = useWindowSize();

  useEffect(() => {
    if (richTextRef.current && truncateText) {
      if (richTextRef.current.scrollHeight > 66) {
        setExpanded(false);
        setShowButton(true);
      } else {
        setExpanded(true);
        setShowButton(false);
      }
    }
  }, [width]);

  const handleClick = () => {
    setExpanded(prevExpanded => !prevExpanded);
    setMaxHeight(prevHeight => (prevHeight === 0 ? 1000 : 0)); // Toggle between 0 and large value
  };

  useEffect(() => {
    if (richTextRef.current && expanded) {
      setMaxHeight(richTextRef.current.scrollHeight); // Set to actual scroll height on expansion
    }
  }, [expanded]);

  return (
    <div
      className={classNames(
        className,
        { [styles.small]: small, [styles.horizontal]: horizontal },
        styles.contactBlockItem
      )}
    >
      {image && (
        <div className={styles.image}>
          <Image src={image.src} alt={image.alt} />
        </div>
      )}
      <div
        className={classNames(styles.contentContainer, {
          [styles.smallHorizontal]: small && horizontal,
          [styles.horizontal]: horizontal && !small,
          [styles.noSubHeading]: !subHeading,
        })}
      >
        {subHeading && <p className={styles.subHeading}>{subHeading}</p>}
        <h3>{name}</h3>
        {ingress && <p className={styles.ingress}>{ingress}</p>}
        {(email || phoneNumber || link?.url) && (
          <ul>
            {email && (
              <li>
                <a href={`mailto:${email}`}>{email}</a>
              </li>
            )}
            {phoneNumber && (
              <li>
                <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
              </li>
            )}
            {link?.url && (
              <li className={styles.link}>
                <a href={link.url}>
                  <span>{link.text}</span>
                </a>
                <IconMS
                  className={styles.linkIcon}
                  name="open_in_new"
                  size="16px"
                />
              </li>
            )}
          </ul>
        )}

        {text && text.items.length > 0 && (
          <div
            className={styles.richText}
            ref={richTextRef}
            style={{ maxHeight: `${maxHeight}px`, marginBottom: "24px" }} // Use maxHeight dynamically
          >
            <RichTextBlock text={text} />
          </div>
        )}
        {showButton && (
          <button className={styles.button} onClick={handleClick}>
            {expanded ? showLessText : showMoreText}
          </button>
        )}
      </div>
    </div>
  );
};

export default ContactBlockItem;
