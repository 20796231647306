//NOTE: Copy and paste from MIKA to just have *something* in the components folder. Should be removed or refactored for IMDI later

export interface NoraCtaBlockProps {
  title?: string;
}

const NoraCtaBlock: React.FC<NoraCtaBlockProps> = ({ title }) => {
  return <h1>Hello</h1>;
};

export default NoraCtaBlock;
