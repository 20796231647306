import { ReactComponentPropsBase } from "../../base-props/ReactComponentPropsBase";
import ContentArea, {
  ContentAreaProps,
} from "../../components/utils/ContentArea/ContentArea";
import styles from "./MangfoldstestResultPage.module.scss";
import ResultHeroBlock, {
  ResultHeroBlockProps,
} from "src/components-mika/blocks/ResultHeroBlock/ResultHeroBlock";

export interface MangfoldstestResultPageProps extends ReactComponentPropsBase {
  resultHero: ResultHeroBlockProps;
  contentArea: ContentAreaProps;
}

const MangfoldstestResultPage: React.FC<MangfoldstestResultPageProps> = ({
  contentArea,
  resultHero,
}) => {
  return (
    <main className={styles.mangfoldstestResultPage}>
      {resultHero && <ResultHeroBlock {...resultHero} />}
      {contentArea && <ContentArea {...contentArea} />}
    </main>
  );
};

export default MangfoldstestResultPage;
