import { ContentAreaProps } from "components/utils/ContentArea/ContentArea";
import RichTextBlock from "components/blocks/RichTextBlock/RichTextBlock";

import styles from "./FactBoxBlock.module.scss";
import classNames from "classnames";

export interface FactBoxBlockProps {
  text: ContentAreaProps;
  theme?: "white" | "yellow";
  heading: string;
}

const QuestionMarkIcon = () => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.458 32.75L22.5413 32.75V28.6667H18.458V32.75ZM20.4997 0.083374C9.22968 0.083374 0.0830078 9.23004 0.0830078 20.5C0.0830078 31.77 9.22968 40.9167 20.4997 40.9167C31.7697 40.9167 40.9163 31.77 40.9163 20.5C40.9163 9.23004 31.7697 0.083374 20.4997 0.083374ZM20.4997 36.8334C11.4959 36.8334 4.16634 29.5038 4.16634 20.5C4.16634 11.4963 11.4959 4.16671 20.4997 4.16671C29.5034 4.16671 36.833 11.4963 36.833 20.5C36.833 29.5038 29.5034 36.8334 20.4997 36.8334ZM20.4997 8.25004C15.9876 8.25004 12.333 11.9046 12.333 16.4167H16.4163C16.4163 14.1709 18.2538 12.3334 20.4997 12.3334C22.7455 12.3334 24.583 14.1709 24.583 16.4167C24.583 20.5 18.458 19.9896 18.458 26.625H22.5413C22.5413 22.0313 28.6663 21.5209 28.6663 16.4167C28.6663 11.9046 25.0118 8.25004 20.4997 8.25004Z"
      fill="black"
    />
  </svg>
);

const FactBoxBlock: React.FC<FactBoxBlockProps> = ({
  text,
  theme = "white",
  heading,
}) => {
  return (
    <div className={classNames(styles.factBox, styles[theme])}>
      <div className={styles.titleContainer}>
        <h2>{heading}</h2>
        <div className={styles.icon}>
          <QuestionMarkIcon />
        </div>
      </div>
      <RichTextBlock text={text} />
    </div>
  );
};

export default FactBoxBlock;
