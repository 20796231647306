import "./RichTextBlock.scss";
import { ContentAreaProps } from "../../utils/ContentArea/ContentArea";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import DynamicBlock from "src/components/utils/DynamicBlock/DynamicBlock";
import LayoutBlock from "src/components/layout/LayoutBlock/LayoutBlock";

export interface RichTextBlockProps extends ReactComponentPropsBase {
  text: ContentAreaProps;
}

const RichTextBlock: React.FC<RichTextBlockProps> = ({ text }): any => {
  return text.items.map((comp, index) =>
    text.topLevelRichText ? (
      <LayoutBlock key={`${comp.componentName}-${index}`} {...comp} />
    ) : (
      <DynamicBlock {...comp} key={`${comp.componentName}-${index}`} />
    )
  );
};

export default RichTextBlock;
