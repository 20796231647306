import { FC } from "react";
import styles from "./ContentArea.module.scss";
import classNames from "classnames";
import LayoutBlock, {
  LayoutBlockProps,
} from "components/layout/LayoutBlock/LayoutBlock";
import DynamicBlock from "../DynamicBlock/DynamicBlock";

export interface ContentAreaProps {
  items: LayoutBlockProps[];
  topLevelRichText?: boolean;
  propertyName?: string;
  className?: string;
  isInsideBlock?: boolean;
  noStyling?: boolean;
}

const ContentArea: FC<ContentAreaProps> = ({
  items,
  className,
  propertyName,
  isInsideBlock,
  noStyling,
}) => {
  return (
    <div
      className={classNames(className, {
        "content-area-react__edit-placeholder": propertyName,
        [styles.isInsideBlock]: isInsideBlock && !noStyling,
      })}
      data-epi-edit={propertyName}
      id={!isInsideBlock ? "page-level-content-area" : undefined}
    >
      {items?.map(
        (item, index) =>
          item &&
          (isInsideBlock ? (
            <DynamicBlock key={`${item.componentName}-${index}`} {...item} />
          ) : (
            <LayoutBlock key={`${item.componentName}-${index}`} {...item} />
          ))
      )}
    </div>
  );
};

export default ContentArea;
