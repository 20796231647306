import classNames from "classnames";
import useSelectTheme from "../../../utils/useSelectTheme";
import styles from "./MikaQuoteBlock.module.scss";
import getRandom from "src/utils/getRandom";
import { Icon } from "SiteComponents";

const illustrations = [
  "mika-motiver/motiv-1-1",
  "mika-motiver/motiv-1-2",
  "mika-motiver/motiv-1-3",
  "mika-motiver/motiv-2-1",
  "mika-motiver/motiv-2-2",
  "mika-motiver/motiv-2-3",
  "mika-motiver/motiv-3-1",
  "mika-motiver/motiv-3-2",
  "mika-motiver/motiv-3-3",
];

export interface MikaQuoteBlockProps {
  quote: string;
  citationGiverName?: string;
  citationGiverTitle?: string;
  withIllustration: boolean;
}

const MikaQuoteBlock: React.FC<MikaQuoteBlockProps> = ({
  quote,
  citationGiverName,
  citationGiverTitle,
  withIllustration = true,
}) => {
  const selectedTheme = useSelectTheme("", "blue");

  return (
    <div className={classNames(styles.mikaQuoteBlock, styles[selectedTheme])}>
      {withIllustration && <Icon name={getRandom(illustrations)} />}
      <blockquote>
        <p> - {quote}</p>
        {citationGiverName && (
          <div className={styles.authorContainer}>
            <span>{citationGiverName}</span>
            <span>
              <i>{citationGiverTitle && citationGiverTitle}</i>
            </span>
          </div>
        )}
      </blockquote>
    </div>
  );
};

export default MikaQuoteBlock;
