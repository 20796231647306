import styles from "./ImdiLogo.module.scss";
import useWindowSize from "src/utils/useWindowSize";
import { Icon } from "SiteComponents";
import classNames from "classnames";

export interface ImdiLogoProps {
  className?: string;
  withText?: boolean;
}

const ImdiLogo: React.FC<ImdiLogoProps> = ({className, withText}) => {
  const isDesktop = useWindowSize().width > 768;

  return (
    <Icon
      className={classNames(styles.imdiLogo, className)}
      name={isDesktop || withText ? "imdi-logo-with-text" : "imdi-logo"}
    />
  );
};

export default ImdiLogo;
