import { useContext, createContext } from "react";

export const ThemeContext = createContext<
  "white" | "green" | "dark" | "gray" | "light"
>("white");

export function useTheme() {
  const theme = useContext(ThemeContext);

  if (theme === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }

  return theme;
}
