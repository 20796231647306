import React from "react";
import styles from "./MangfoldsTestNav.module.scss";
import { ReactComponentPropsBase } from "src/base-props/ReactComponentPropsBase";
import Icon from "components/utils/Icon/Icon";
import Button from "components/base/Button/Button";

export interface MangfoldsTestNavProps extends ReactComponentPropsBase {
  currentIndex: number;
  totalQuestions: number;
  prevText: string;
  homeLinkText: string;
  prev: () => void;
  cancel: () => void;
  isOnPreviousSlide?: boolean;
}

const MangfoldsTestNav: React.FC<MangfoldsTestNavProps> = ({
  currentIndex,
  totalQuestions,
  prevText,
  homeLinkText,
  prev,
}) => {
  const canGoBack = currentIndex > 0;

  return (
    <div className={styles.mangfoldsTestNav}>
      <div className={styles.backButtonContainer}>
        {prevText && canGoBack && (
          <Button onClick={prev} theme="secondary">
            {prevText}
          </Button>
        )}
      </div>
      <div className={styles.progressMobile}>
        {currentIndex + 1} av {totalQuestions}
      </div>
      <div className={styles.progressDesktop}>
        Spørsmål {currentIndex + 1} av {totalQuestions}
      </div>
      <Icon className={styles.logo} name="imdi-logo" />
    </div>
  );
};

export default MangfoldsTestNav;
