import classNames from "classnames";

import styles from "./MouseLink.module.scss";

export interface MouseLinkProps {
  children: React.ReactNode;
  className?: string;
  url: string;
}
const MouseLink: React.FC<MouseLinkProps> = ({ children, className, url }) => {
  const handleDivMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!url) return;
    const innerLinkClicked =
      (e.target as Element).tagName.toLowerCase() === "a";
    if (innerLinkClicked) return;
    const isMiddleButtonClicked = e.button === 1;
    if (isMiddleButtonClicked) {
      // open in new tab
      e.preventDefault();
      window.open(url, "_blank");
    } else if (e.button === 0) {
      window.location.href = url;
    }
  };

  return (
    <div
      className={classNames(styles.mouseLink, className)}
      onMouseDown={handleDivMouseDown}
    >
      {children}
    </div>
  );
};

export default MouseLink;
