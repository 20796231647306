import styles from "./HorizontalSectionBlock.module.scss";
import classNames from "classnames";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";

export type HorizontalSectionBlockProps = {
  ingress?: string;
  heading?: string;
  contentArea: ContentAreaProps;
};

const HorizontalSectionBlock: React.FC<HorizontalSectionBlockProps> = ({
  heading,
  ingress,
  contentArea,
}) => {
  return (
    <div
      className={classNames(styles.horizontalSectionBlock, {
        [styles.hasThreeBlocks]: contentArea?.items?.length === 3,
      })}
      // @ts-ignore
      style={{ "--block-count": contentArea?.items?.length }}
    >
      <div className={styles.blockContainer}>
        <div className={styles.textContent}>
          {heading && <h2 className={styles.heading}>{heading}</h2>}
          {ingress && <p className={styles.ingress}>{ingress}</p>}
        </div>

        {contentArea && (
          <ContentArea
            className={styles.blocks}
            {...contentArea}
            isInsideBlock
          />
        )}
      </div>
    </div>
  );
};

export default HorizontalSectionBlock;
