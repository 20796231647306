import styles from "./SearchField.module.scss";
import classNames from "classnames";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import Button from "../Button/Button";
import Icon from "components/utils/Icon/Icon";
import { useEffect, useRef, useState } from "react";
import IconMS from "src/components/utils/IconMS/IconMS";
import emitter from "src/utils/emitter";
import useHeroBackground from "src/utils/use-hero-background/useHeroBackground";
import { useTheme } from "../../../utils/useTheme";

export interface SearchFieldProps extends ReactComponentPropsBase {
  placeholder?: string;
  buttonLabel?: string;
  label?: string;
  search?: (query: string) => void;
  searchWord?: string;
  withIcon?: boolean;
}

const SearchField: React.FC<SearchFieldProps> = ({
  placeholder,
  buttonLabel,
  label,
  search,
  searchWord,
  withIcon,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(searchWord || "");
  const inputRef = useRef<HTMLInputElement>(null);

  //const backgroundClass = useHeroBackground();
  const theme = useTheme();
  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
    String(value).length > 1 && emitter.emit("search", value);
  };

  const handleClear = () => {
    setInputValue("");
    inputRef?.current?.focus();
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    search && search(inputValue);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames(styles.searchField, {})}
    >
      <div
        className={classNames(styles.inputContainer, {
          [styles.focused]: isFocused,
          [styles.dark]: theme === "dark",
        })}
      >
        <label
          className={styles.inputLabel}
          htmlFor="searchField"
          aria-label={label}
        >
          {withIcon && (
            <IconMS
              className={styles.searchIcon}
              name="search"
              size="24px"
              fill
            />
          )}
          <input
            id="searchField"
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={handleChanges}
            placeholder={placeholder}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </label>
        <button
          className={classNames(styles.clearButton, {
            [styles.showClearButton]: inputValue.length > 0,
          })}
          onClick={handleClear}
          type="button"
        >
          <Icon name="close" />
        </button>
      </div>
      <Button disabled={inputValue.length < 2} className={styles.submit}>
        <IconMS name="search" size="24px" />
        {buttonLabel}
      </Button>
    </form>
  );
};

export default SearchField;
