class CacheService {
  private cache: Record<string, any> = {};

  get<T>(key: string): T | undefined {
    return this.cache[key];
  }

  set<T>(key: string, data: T): void {
    this.cache[key] = data;
  }

  clear(): void {
    this.cache = {};
  }
}

export const cacheService = new CacheService();
