import React from "react";
import styles from "./TimelineBlock.module.scss";
import { LinkProps } from "src/base-props/LinkProps";

export type TimelineProps = {
  stepLabel?: string;
  timelineItems: LinkProps[];
};

const TimelineBlock: React.FC<TimelineProps> = ({
  stepLabel = "step",
  timelineItems,
}) => {
  if (timelineItems?.length >= 2) {
    return (
      <div>
        <ol className={styles.list}>
          {timelineItems &&
            timelineItems.length > 0 &&
            timelineItems.map((item, index) => (
              <li key={item.text}>
                <a
                  href={item.url}
                  aria-label={`${stepLabel} ${index + 1} - ${item.text}`}
                >
                  <span className={styles.number}>{index + 1}</span>
                  <span className={styles.line} />
                  <div className={styles.text}>{item.text}</div>
                </a>
              </li>
            ))}
        </ol>
      </div>
    );
  } else {
    return null;
  }
};

export default TimelineBlock;
