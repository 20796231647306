import React from "react";
import styles from "./TilskuddPageHeroBlock.module.scss";
import classNames from "classnames";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import IconMS from "src/components/utils/IconMS/IconMS";
import useHeroBackground from "src/utils/use-hero-background/useHeroBackground";
import { useTheme } from "src/utils/useTheme";
import { LinkProps } from "src/base-props/LinkProps";
import { CTA } from "SiteComponents";
import { ReactComponentPropsBase } from "src/base-props/ReactComponentPropsBase";
import { TagProps } from "components/base/Tag/Tag";
import Tag from "../../base/Tag/Tag";

export interface TilskuddPageHeroBlockProps extends ReactComponentPropsBase {
  button?: LinkProps;
  deadLine?: string;
  deadLineDate?: string;
  heading: string;
  grantInfo?: string;
  grantLabel?: string;
  lastUpdated?: string;
  subHeading?: string;
  tagTitle?: string;
  tags?: TagProps[];
}

const TilskuddPageHeroBlock: React.FC<TilskuddPageHeroBlockProps> = ({
  button,
  deadLine,
  deadLineDate,
  heading,
  grantInfo,
  grantLabel,
  lastUpdated,
  subHeading,
  tagTitle,
  tags,
}) => {
  const backgroundClass = useHeroBackground();
  const theme = useTheme();

  return (
    <div className={backgroundClass}>
      <BlockContainer
        className={classNames(styles.tilskuddPageHeroBlock, {
          [styles.dark]: theme === "dark",
        })}
        verticalPadding
      >
        {subHeading && <p className={styles.subHeading}>{subHeading}</p>}
        <h1>{heading}</h1>
        <div className={styles.content}>
          {button && <CTA className={styles.button} {...button} />}
          <div className={styles.detailsContainer}>
            {deadLine && deadLineDate && (
              <div className={styles.deadLine}>
                <p>{deadLine}</p>
                <p>{deadLineDate}</p>
              </div>
            )}
            {grantLabel && grantInfo && (
              <div className={styles.grant}>
                <p>{grantLabel}</p>
                <p>{grantInfo}</p>
              </div>
            )}

            {tagTitle && tags && (
              <div className={styles.tagContainer}>
                <p>{tagTitle}</p>
                <div className={styles.tags}>
                  {tags.map(tag => (
                    <Tag {...tag} onDark={theme === "dark"} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {lastUpdated && (
          <div className={styles.updatedContainer}>
            <IconMS className={styles.icon} name="stylus" size="20px" />
            <p>{lastUpdated}</p>
          </div>
        )}
      </BlockContainer>
    </div>
  );
};

export default TilskuddPageHeroBlock;
