import classNames from "classnames";
import styles from "./Tag.module.scss";

export interface TagProps {
  text: string;
  className?: string;
  onDark?: boolean;
}

const Tag = ({ text, className, onDark = false }: TagProps) => {
  return (
    <div
      title={text}
      className={classNames(styles.tag, className, {
        [styles.onDark]: onDark,
      })}
    >
      {text}
    </div>
  );
};

export default Tag;
