import React from "react";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import GridContainer from "components/layout/Grid/GridContainer";
import GridItem from "components/layout/Grid/GridItem";
import ContentAnchors, {
  ContentAnchorsProps,
} from "components/layout/ContentAnchors/ContentAnchors";
import AlertBlock, {
  AlertBlockProps,
} from "components/blocks/AlertBlock/AlertBlock";
import classNames from "classnames";
import styles from "./TopPageBlocks.module.scss";
import HighlightedInfoBlock, {
  HighlightedInfoBlockProps,
} from "components/blocks/HighlightedInfoBlock/HighlightedInfoBlock";

export interface TopPageBlocksProps extends ReactComponentPropsBase {
  alert?: AlertBlockProps;
  mainIntro?: string;
  contentAnchors?: ContentAnchorsProps;
  className?: string;
  width?: "narrow" | "wide";
  highlightedInfo?: HighlightedInfoBlockProps;
}

const blockWidths = {
  narrow: { xs: 12, lg: 6, lgOffset: 3 },
  wide: { xs: 12 },
};

const TopPageBlocks: React.FC<TopPageBlocksProps> = ({
  alert,
  mainIntro,
  contentAnchors,
  className,
  width = "narrow",
  highlightedInfo,
}) => {
  if (!alert && !mainIntro && !contentAnchors) return null;

  return (
    <GridContainer
      horizontalSpacing="lg"
      className={classNames(styles.topPageBlocks, className)}
    >
      {alert && (
        <GridItem {...blockWidths[width]}>
          <AlertBlock {...alert} />
        </GridItem>
      )}

      {mainIntro && (
        <GridItem {...blockWidths[width]}>
          <p className={styles.mainIntro}>{mainIntro}</p>
        </GridItem>
      )}
      {highlightedInfo && <HighlightedInfoBlock {...highlightedInfo} />}

      {contentAnchors && (
        <GridItem {...blockWidths[width]}>
          <ContentAnchors {...contentAnchors} />
        </GridItem>
      )}
    </GridContainer>
  );
};

export default TopPageBlocks;
