import classNames from "classnames";
import Image, { ImageProps } from "../../../components/base/Image/Image";
import styles from "./FeaturedArticlesCard.module.scss";
import useSelectTheme from "../../../utils/useSelectTheme";
import placeholder from "../../../assets/images/placeholder.png";

export interface FeaturedArticlesCardProps {
  heading: string;
  image?: ImageProps;
  cardTheme?: "gray" | "green" | "white";
  url: string;
}

const FeaturedArticlesCard: React.FC<FeaturedArticlesCardProps> = ({
  heading,
  image,
  cardTheme,
  url,
}) => {
  const selectedTheme = useSelectTheme("", "blue");
  return (
    <a
      className={classNames(
        styles.featuredArticlesCard,
        styles[selectedTheme],
        {
          [styles.green]: cardTheme === "green",
          [styles.white]: cardTheme === "white",
          [styles.gray]: cardTheme === "gray",
        }
      )}
      href={url}
    >
      <div className={styles.imageContainer}>
        {image ? (
          <Image src={image.src} alt={image.alt} />
        ) : (
          <Image
            src={placeholder}
            alt="Placeholder image of orange triangles on grey background"
          />
        )}
      </div>
      <h3>{heading}</h3>
    </a>
  );
};

export default FeaturedArticlesCard;
