import React, { ReactNode } from "react";
import styles from "./SimpleLink.module.scss";
import classNames from "classnames";
import { useTheme } from "src/utils/useTheme";
import { IconMS } from "SiteComponents";

export interface SimpleLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  url: string;
  text?: string;
  className?: string;
  children?: ReactNode;
  isExternal?: boolean;
  isSubdued?: boolean;
  isUnderlined?: boolean;
  externalLinkDomain?: string;
}

const SimpleLink: React.FC<SimpleLinkProps> = ({
  text,
  url,
  className,
  children,
  isExternal,
  isSubdued,
  isUnderlined,
  externalLinkDomain, //Only used to support legacy external-icon-triggering logic
  ...props
}) => {
  const textOrChildren = children ? children : text;
  const theme = useTheme();

  const showExternalIcon =
    isExternal || (externalLinkDomain && externalLinkDomain.length > 0);
  return (
    <a
      className={classNames(styles.simpleLink, className, {
        [styles.dark]: theme === "dark",
        [styles.subdued]: isSubdued,
        [styles.underlined]: isUnderlined,
        [styles.disabled]: !url || props["aria-disabled"],
      })}
      href={url}
      {...props}
    >
      {textOrChildren}
      {showExternalIcon && (
        <IconMS className={styles.icon} name="open_in_new" size="1em" />
      )}
    </a>
  );
};

export default SimpleLink;
