import styles from "./NyINorgeStartPageHeroBlock.module.scss";
import { BlockContainer } from "../../../NyINorge.Components";

export interface NyINorgeStartPageHeroBlockProps {
  heading: string;
  mainIntro: string;
}

const NyINorgeStartPageHeroBlock: React.FC<NyINorgeStartPageHeroBlockProps> = ({
  heading,
  mainIntro,
}) => {
  return (
    <div className={styles.heroBlock}>
      <BlockContainer className={styles.heroBlockContent}>
        <h1>{heading}</h1>
        <p className={styles.textContainer}>
          {mainIntro}
        </p>
      </BlockContainer>
    </div>
  );
};

export default NyINorgeStartPageHeroBlock;
