import { useState, useEffect, useCallback, useRef } from "react";
import useOnResize from "src/utils/useOnResize";

export function useExpandableContent(breakpointMobile = 768, desktopHeight = 21, mobileHeight = 42) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const calculateTruncation = useCallback(() => {
    if (contentRef.current) {
      const node = contentRef.current;
      const isCurrentlyTruncated = node.scrollHeight > node.clientHeight;
      setIsTruncated(isCurrentlyTruncated);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      calculateTruncation();
    }, 0);
    return () => clearTimeout(timer);
  }, [calculateTruncation]);

  useOnResize(() => {
    if (contentRef.current) {
      const node = contentRef.current;

      if (isExpanded) {
        node.style.maxHeight = `${node.scrollHeight}px`;
        setIsTruncated(true);  // Keep the button showing when expanded
      } else {
        node.style.maxHeight =
          window.innerWidth > breakpointMobile
            ? `${desktopHeight}px`
            : `${mobileHeight}px`;

        // Check if it needs truncation after resize
        const isCurrentlyTruncated = node.scrollHeight > node.clientHeight;
        setIsTruncated(isCurrentlyTruncated);
      }
    }
  });

  useEffect(() => {
    if (contentRef.current) {
      const node = contentRef.current;
      node.style.maxHeight = isExpanded
        ? `${node.scrollHeight}px`
        : `${window.innerWidth > breakpointMobile ? desktopHeight : mobileHeight}px`;
    }
  }, [isExpanded, breakpointMobile, desktopHeight, mobileHeight]);

  return {
    isExpanded,
    isTruncated,
    contentRef,
    setIsExpanded,
  };
}
