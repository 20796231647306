// use swipe hook
import { useState, useEffect } from "react";

const useSwipe = (
  cardList: any[],
  currentSlide: number,
  setCurrentSlide: (currentSlide: number) => void,
  cardWidth: number,
  gap: number,
  visibleCards: number,
  setTranslateX: (translateX: number) => void,
  touchActive: boolean,
  setTouchActive: (touchActive: boolean) => void
) => {
  const [touchStart, setTouchStart] = useState(0);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX);
    setTouchActive(true);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!touchActive) return;
    const currentTouchEnd = e.targetTouches[0].clientX;
    const swipeDistance = touchStart - currentTouchEnd;
    if (Math.abs(swipeDistance) > 80) {
      setTouchActive(false);
      nextSlide(swipeDistance);
      return;
    }

    setTranslateX(currentSlide * (cardWidth + gap) + swipeDistance * 2);
  };

  const nextSlide = (swipeDistance: number) => {
    let newSlideIndex = currentSlide;
    if (swipeDistance > 0 && currentSlide < cardList.length - visibleCards) {
      // swipe left
      newSlideIndex = currentSlide + visibleCards;
    } else if (swipeDistance < 0 && currentSlide > 0) {
      // swipe right
      newSlideIndex = currentSlide - visibleCards;
    }
    setCurrentSlide(newSlideIndex);
    setTranslateX(newSlideIndex * (cardWidth + gap));
  };

  const handleTouchEnd = () => {
    // reset the translateX based on the current slide
    setTranslateX(currentSlide * (cardWidth + gap));
  };

  useEffect(() => {
    const preventVerticalScroll = (e: TouchEvent) => {
      if (touchActive) {
        e.preventDefault();
      }
    };
    window.addEventListener("touchmove", preventVerticalScroll, {
      passive: false,
    });
    return () => window.removeEventListener("touchmove", preventVerticalScroll);
  }, []);
  return {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  };
};

export default useSwipe;
