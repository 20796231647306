import { useEffect } from "react";

const useSearchTermHighlight = (contentId: string, theme: string) => {
  useEffect(() => {
    // Get the query parameter from the URL
    const searchParams = new URLSearchParams(window.location.search);
    let searchTerm = searchParams.get("q") || "";

    //const searchTerm = "Asylum";
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const highlightSearchTerm = (node: Node) => {
      if (node.nodeType === 3) {
        //Search for the searchTerm and wrap it in a span
        const textNode = node as Text;
        const contentHTML = textNode.nodeValue || "";
        const regex = new RegExp(searchTerm, "gi");

        if (regex.test(contentHTML)) {
          const span = document.createElement("span");
          span.innerHTML = contentHTML.replace(
            regex,
            match => `<span class="highlight-${theme}">${match}</span>`
          );
          textNode.replaceWith(span);
        }
      } else if (node.nodeType === 1) {
        const elementNode = node as Element;

        // Check if the element is an accordion
        if (elementNode.hasAttribute("data-accordion")) {
          // Check if the accordion content contains the searchTerm
          const accordionContent = elementNode.querySelector(
            "[data-accordion-content]"
          );

          if 
            (accordionContent &&
            accordionContent?.textContent?.toLowerCase().includes(lowerCaseSearchTerm)
          ) {
            const accordionButton = elementNode.querySelector(
              "[data-accordion-button]"
            ) as HTMLElement;
            if (accordionButton) {
              //Open the accordion with the searchTerm
              accordionButton.click();
            }
          }
        }

        for (const childNode of elementNode.childNodes) {
          highlightSearchTerm(childNode);
        }
      }
    };

    // Should use the id on the article page for the body/main element
    const articleContent = document.getElementById(`${contentId}`);
    if (articleContent && searchTerm) {
      for (const childNode of articleContent.childNodes) {
        highlightSearchTerm(childNode);
      }
    }
  }, []);
};

export default useSearchTermHighlight;
