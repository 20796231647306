import React, { useEffect, useState } from "react";
import { ReactComponentPropsBase } from "src/base-props/ReactComponentPropsBase";

import MangfoldsTestSlideQuestion, {
  MangfoldsTestSlideQuestionProps,
} from "./MangfoldsTestSlideQuestion/MangfoldsTestSlideQuestion";
import MangfoldsTestSlideInfo, {
  MangfoldsTestSlideInfoProps,
  ResultLinkProps,
} from "./MangfoldsTestSlideInfo/MangfoldsTestSlideInfo";

export interface MangfoldsTestSlideProps extends ReactComponentPropsBase {
  slideQuestion: MangfoldsTestSlideQuestionProps;
  userAnswer?: string | null;
  handleAnswer?: (answer: string) => void;
  handleNext?: () => void;
  isOnPreviousSlide?: boolean;
  currentSlide: number;
  resultLink?: ResultLinkProps;
  iconNames: string[];
}

const MangfoldsTestSlide: React.FC<MangfoldsTestSlideProps> = ({
  slideQuestion,
  handleAnswer,
  handleNext,
  currentSlide,
  resultLink,
  iconNames,
}) => {
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    setShowInfo(false);
  }, [currentSlide]);

  const response: MangfoldsTestSlideInfoProps | undefined =
    slideQuestion.answers.find(
      answer => answer.type === slideQuestion.userAnswer
    )?.response;

  const handleAnswerClick = (answer: string) => {
    handleAnswer && handleAnswer(answer);
    setShowInfo(true);
  };

  return (
    <>
      {response && showInfo ? (
        <MangfoldsTestSlideInfo
          onClick={handleNext}
          {...response}
          iconNames={iconNames}
          resultLink={resultLink}
        />
      ) : (
        <MangfoldsTestSlideQuestion
          handleAnswer={handleAnswerClick}
          {...slideQuestion}
        />
      )}
    </>
  );
};

export default MangfoldsTestSlide;
