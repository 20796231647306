import { useContext } from "react";
import { GridItemStyleProps } from "./GridItem";
import {
  GridOverrideContext,
  GridOverrideContextProps,
} from "src/utils/GridOverrideContext";

const getLargestValue = (
  values: Array<number | undefined>,
  defaultValue: number
): number => {
  return values.find(value => value !== undefined) ?? defaultValue;
};

const coalesceStyleProps = (
  styleProps: GridItemStyleProps,
  styleOptionsOverridingContext: GridOverrideContextProps
): GridItemStyleProps => {
  return Object.keys(styleProps).reduce((acc, key) => {
    const typedKey = key as keyof GridItemStyleProps;
    return {
      ...acc,
      [typedKey]: extractContextOption(typedKey),
    };

    function extractContextOption(key: keyof GridItemStyleProps) {
      const value = styleOptionsOverridingContext[key];
      if (typeof value === "function") {
        return value(styleProps[key] as number);
      }
      return value ?? styleProps[key];
    }
  }, {} as GridItemStyleProps);
};

const useGridItemStyle = (styleOptions: GridItemStyleProps) => {
  const styleOptionsOverridingContext = useContext(GridOverrideContext);

  const {
    xs: finalXs,
    sm: finalSm,
    md: finalMd,
    lg: finalLg,
    xl: finalXl,
    padding: finalPadding,
    xsOffset: finalXsOffset,
    smOffset: finalSmOffset,
    mdOffset: finalMdOffset,
    lgOffset: finalLgOffset,
    xlOffset: finalXlOffset,
  } = coalesceStyleProps(styleOptions, styleOptionsOverridingContext);

  // Ensure that width and offset are the largest available value, or default to 12 for widths and 0 for offsets
  const itemStyle = {
    "--padding": finalPadding,
    "--xs-width": getLargestValue([finalXs, 12], 12), // MAke getLargest actually jst pick the largest value, not the first
    "--sm-width": getLargestValue([finalSm, finalXs, 12], 12),
    "--md-width": getLargestValue([finalMd, finalSm, finalXs, 12], 12),
    "--lg-width": getLargestValue([finalLg, finalMd, finalSm, finalXs, 12], 12),
    "--xl-width": getLargestValue(
      [finalXl, finalLg, finalMd, finalSm, finalXs, 12],
      12
    ),
    "--xs-offset": getLargestValue([finalXsOffset, 0], 0),
    "--sm-offset": getLargestValue([finalSmOffset, finalXsOffset, 0], 0),
    "--md-offset": getLargestValue(
      [finalMdOffset, finalSmOffset, finalXsOffset, 0],
      0
    ),
    "--lg-offset": getLargestValue(
      [finalLgOffset, finalMdOffset, finalSmOffset, finalXsOffset, 0],
      0
    ),
    "--xl-offset": getLargestValue(
      [
        finalXlOffset,
        finalLgOffset,
        finalMdOffset,
        finalSmOffset,
        finalXsOffset,
        0,
      ],
      0
    ),
  };

  return itemStyle;
};

export default useGridItemStyle;
