import { useContext } from "react";
import styles from "./GridItem.module.scss";
import classNames from "classnames";
import {
  GridOverrideContext,
  GridOverrideContextProps,
} from "src/utils/GridOverrideContext";
import useGridItemStyle from "./useGridItemStyle";
export interface GridItemStyleProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  padding?: number;
  xsOffset?: number;
  smOffset?: number;
  mdOffset?: number;
  lgOffset?: number;
  xlOffset?: number;
}

interface GridItemProps extends GridItemStyleProps {
  children: React.ReactNode;
  className?: string;
}

const GridItem: React.FC<GridItemProps> = ({
  children,
  className,
  ...styleOptions
}) => {
  const itemStyle = useGridItemStyle(styleOptions);

  return (
    <div className={classNames(styles.gridItem, className)} style={itemStyle}>
      {children}
    </div>
  );
};

export default GridItem;
