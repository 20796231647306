import React from "react";
import classNames from "classnames";
import styles from "./AlertBlock.module.scss";
import RichText from "src/components/base/RichText/RichText";
import { useExpandableContent } from "src/utils/useExpandableContent";
import { Icon } from "SiteComponents";

export interface AlertBlockProps {
  title?: string;
  message: string;
  alertType: "informative" | "positive" | "negative" | "warning";
  showMoreText: string;
  showLessText: string;
}

const AlertBlock: React.FC<AlertBlockProps> = ({
  title,
  message,
  alertType,
  showMoreText,
  showLessText,
}) => {
  const { isExpanded, isTruncated, contentRef, setIsExpanded } =
    useExpandableContent();

  return (
    <div className={classNames(styles.alertBlock, styles[alertType])}>
      <Icon className={styles.icon} name={`alert-block-${alertType}`} />
      <div className={styles.message}>
        {title && <h2>{title}</h2>}
        <RichText
          className={classNames(styles.richText, {
            [styles.isExpanded]: isExpanded,
          })}
          textRefFunction={node => {
            if (node) {
              contentRef.current = node;
            }
          }}
          html={message}
        />
        {isTruncated && (
          <button
            className={styles.expandButton}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? showLessText : showMoreText}
          </button>
        )}
      </div>
    </div>
  );
};

export default AlertBlock;
