import React from "react";
import HeroBlock, {
  HeroBlockProps,
} from "components/blocks/HeroBlock/HeroBlock";
import { ReactComponentPropsBase } from "../../base-props/ReactComponentPropsBase";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import useSearchTermHighlight from "src/utils/useHighlightSearchTerm";
import { useTheme } from "src/utils/useTheme";

export interface ArticlePageProps extends ReactComponentPropsBase {
  hero?: HeroBlockProps;
  contentArea: ContentAreaProps;
}

const ArticlePage: React.FC<ArticlePageProps> = ({ contentArea, hero }) => {
  const theme = useTheme();
  useSearchTermHighlight("article-page", theme);

  return (
    <main className="articlePage" id="article-page">
      {hero && <HeroBlock {...hero} />}
      {contentArea && <ContentArea {...contentArea} />}
    </main>
  );
};

export default ArticlePage;
