import React, { useEffect, useState } from "react";
import styles from "./Icon.module.scss";
import classNames from "classnames";

type IconProps = {
  name: string;
  className?: string;
};

const svgCache: { [key: string]: string } = {};

const Icon: React.FC<IconProps> = ({ name, className }) => {
  const [svgContent, setSvgContent] = useState("");

  useEffect(() => {
    const loadSvg = async () => {
      if (svgCache[name]) {
        setSvgContent(svgCache[name]);
      } else {
        try {
          const svgString = (
            await import(`!!raw-loader!../../../assets/icons/${name}.svg`)
          ).default;
          svgCache[name] = svgString;
          setSvgContent(svgString);
        } catch (error) {
          console.error(`Error loading SVG: ${name}`, error);
          setSvgContent("<svg><text>Error loading SVG</text></svg>");
        }
      }
    };

    loadSvg();
  }, [name]);

  return (
    <div
      aria-hidden="true"
      className={classNames(styles.icon, className)}
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  );
};

export default Icon;
