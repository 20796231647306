import { FC, useContext, ReactNode, useState } from "react";
import { AccordionContext } from "./Accordion";
import styles from "./Accordion.module.scss";
import classNames from "classnames";

interface AccordionItemProps {
  children: (isOpen: boolean, toggleAccordion: () => void) => ReactNode;
  id?: string;
  className?: string;
  isGlobal?: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement>;
}

const AccordionButton: FC<AccordionItemProps> = ({
  children,
  id,
  className,
  isGlobal,
  buttonRef,
}) => {
  const { isOpen, toggleAccordion } = useContext(AccordionContext)!;

  const [inFocus, setInFocus] = useState(false);

  const handleFocus = () => {
    setInFocus(true);
  };

  const handleBlur = () => {
    setInFocus(false);
  };

  return (
    <span>
      <button
        data-accordion-button
        className={classNames(styles.button, className, {
          [styles.focusing]: inFocus && isGlobal,
        })}
        onClick={toggleAccordion}
        aria-expanded={isOpen}
        aria-controls={id}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseLeave={handleBlur}
        onMouseEnter={handleFocus}
        ref={node => {
          if (buttonRef) {
            //@ts-ignore
            buttonRef.current = node;
          }
        }}
      >
        {children(isOpen, toggleAccordion)}
      </button>
    </span>
  );
};
export default AccordionButton;
