import { FC } from "react";
import ErrorText from "components/utils/ErrorText/ErrorText";

/*
 * The 'SiteComponents' import dynamically refers to different component sets
 * based on the context of execution:
 *   - During the build process for front-end (FE), it refers to either 'MIKA.Components' or 'NyINorge.Components'.
 *   - When running Storybook, it points to 'App.Components'.
 */
import * as Components from "SiteComponents";

import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import { IReactComponentProps } from "../../../base-props/IReactComponentProps";
import { GridContainerStyleProps } from "components/layout/Grid/GridContainer";

export interface DynamicBlockProps extends ReactComponentPropsBase {
  componentName: keyof typeof Components;
  component?: IReactComponentProps;
  additionalGridContainerProps?: GridContainerStyleProps;
}

const DynamicBlock: FC<DynamicBlockProps> = ({ componentName, component }) => {
  const Block = Components[componentName];
  if (!Block) {
    return (
      <ErrorText>
        Block not found in <strong>components/DynamicBlock.tsx!</strong>
        <br />
        componentName must match the name of block component.
        <br />
        componentName: <strong>{componentName}</strong>
        <br />
        component blocks:
        {Object.keys(Components).map(key => (
          <strong key={key}>{key}, </strong>
        ))}
      </ErrorText>
    );
  }
  /* @ts-ignore */
  return <Block {...component} />;
};

export default DynamicBlock;
