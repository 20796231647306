import React, { useEffect, useRef } from "react";
import styles from "./AnimateIn.module.scss";
import classNames from "classnames";

export type AnimateInProps = {
  children?: React.ReactNode;
  className?: string;
  delay?: number;
};

export const AnimateIn: React.FC<AnimateInProps> = ({
  children,
  className,
  delay,
}) => {
  const animateInRef = useRef<HTMLDivElement>(null);
  const style = {
    animationDelay: `${delay}s`,
  };

  useEffect(() => {
    //refreshes the animation
    const element = animateInRef.current;
    if (!element) return;
    element.classList.remove(styles.animateIn);
    void element?.offsetWidth; // trigger a DOM reflow
    element.classList.add(styles.animateIn);
  }, [children]);

  return (
    <div
      style={style}
      ref={animateInRef}
      className={classNames(styles.animateIn, className)}
    >
      {children}
    </div>
  );
};

export default AnimateIn;
