import styles from "./FactBoxLargeBlock.module.scss";
import classNames from "classnames";
import RichTextBlock from "components/blocks/RichTextBlock/RichTextBlock";
import { ContentAreaProps } from "components/utils/ContentArea/ContentArea";
import useSelectTheme from "../../../utils/useSelectTheme";

export interface FactBoxLargeBlockProps {
  text: ContentAreaProps;
  theme?: "grey" | "green";
  heading: string;
}

const FactBoxLargeBlock: React.FC<FactBoxLargeBlockProps> = ({
  text,
  theme = "green",
  heading,
}) => {
  const selectedTheme = useSelectTheme(theme, "blue");

  return (
    <div className={classNames(styles.factBoxLarge, styles[selectedTheme])}>
      <h2 className={styles.heading}>{heading}</h2>
      <RichTextBlock text={text} />
    </div>
  );
};

export default FactBoxLargeBlock;
