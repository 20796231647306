import { createContext } from "react";

export interface GridOverrideContextProps {
  xs?: number | ((currentValue: number) => number);
  sm?: number | ((currentValue: number) => number);
  md?: number | ((currentValue: number) => number);
  lg?: number | ((currentValue: number) => number);
  xl?: number | ((currentValue: number) => number);
  padding?: number | ((currentValue: number) => number);
  xsOffset?: number | ((currentValue: number) => number);
  smOffset?: number | ((currentValue: number) => number);
  mdOffset?: number | ((currentValue: number) => number);
  lgOffset?: number | ((currentValue: number) => number);
  xlOffset?: number | ((currentValue: number) => number);
}

export const GridOverrideContext = createContext<GridOverrideContextProps>({});
