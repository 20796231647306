import classNames from "classnames";
import Image, { ImageProps } from "../../../components/base/Image/Image";
import styles from "./RelatedArticlesCard.module.scss";
import useSelectTheme from "../../../utils/useSelectTheme";
import MouseLink from "components/utils/MouseLink/MouseLink";
import { RichText } from "src/MIKA.Components";
import placeholder from "../../../assets/images/placeholder.png";

export interface RelatedArticlesCardProps {
  heading: string;
  intro: string;
  url: string;
  image?: ImageProps;
  theme?: "white" | "green";
}
const RelatedArticlesCard: React.FC<RelatedArticlesCardProps> = ({
  heading,
  intro,
  url,
  image,
  theme = "default",
}) => {
  const selectedTheme = useSelectTheme(theme, "blue");

  return (
    <MouseLink
      className={classNames(styles.relatedArticlesCard, styles[selectedTheme])}
      url={url}
    >
      <a href={url}>
        <div className={(styles.imageContainer, styles.orderFirst)}>
          {image ? (
            <Image src={image.src} alt={image.alt} />
          ) : (
            <Image
              src={placeholder}
              alt="Placeholder image of orange triangles on grey background"
            />
          )}
        </div>

        <h3>{heading}</h3>
        {intro && <RichText className={styles.intro} html={intro} />}
      </a>
    </MouseLink>
  );
};

export default RelatedArticlesCard;
