import styles from "./InfoPage.module.scss";

import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import InfoPageHeroBlock from "components/blocks/InfoPageHeroBlock/InfoPageHeroBlock";
import { InfoPageHeroBlockProps } from "components/blocks/InfoPageHeroBlock/InfoPageHeroBlock";

import TopPageBlocks, {
  TopPageBlocksProps,
} from "components/shared/TopPageBlocks/TopPageBlocks";
import CardListBlock, {
  CardListBlockProps,
} from "components/blocks/CardListBlock/CardListBlock";
import { BlockContainer } from "SiteComponents";

export interface InfoPageProps extends TopPageBlocksProps {
  contentArea: ContentAreaProps;
  hero: InfoPageHeroBlockProps;
  relatedArticles?: CardListBlockProps;
}

const InfoPage: React.FC<InfoPageProps> = ({
  contentArea,
  hero,
  alert,
  mainIntro,
  contentAnchors,
  relatedArticles,
}) => {
  return (
    <main className={styles.infoPage}>
      {hero && <InfoPageHeroBlock {...hero} />}

      <TopPageBlocks
        alert={alert}
        mainIntro={mainIntro}
        contentAnchors={contentAnchors}
      />

      {contentArea && <ContentArea {...contentArea} />}
      {relatedArticles && (
        <BlockContainer>
          <CardListBlock {...relatedArticles} />
        </BlockContainer>
      )}
    </main>
  );
};

export default InfoPage;
