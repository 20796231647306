import VimeoPlayer from "react-player/vimeo";
import YouTubePlayer from "react-player/youtube";
export interface VideoPlayerProps {
  className?: string;
  url: string;
  autoPlay?: boolean;
  disableButtons?: boolean;
  onLoad: () => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  className,
  url,
  autoPlay = false,
  disableButtons = false,
  onLoad,
}) => {
  const isVimeo = url.includes("vimeo");
  const isYouTube = url.includes("youtube");

  if (!isVimeo && !isYouTube) {
    console.error("VideoPlayer: Unsupported video URL", url);
    return null;
  }

  const handleVideoDidLoad = () => {
    onLoad();
  };

  return (
    <>
      {isVimeo && (
        <VimeoPlayer
          url={url}
          controls={true}
          width="100%"
          height="100%"
          playing={autoPlay}
          onReady={handleVideoDidLoad}
        />
      )}
      {isYouTube && (
        <YouTubePlayer
          url={url}
          controls={true}
          width="100%"
          height="100%"
          playing={autoPlay}
          onReady={handleVideoDidLoad}
        />
      )}
    </>
  );
};

export default VideoPlayer;
