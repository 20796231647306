import styles from "./AccordionListBlock.module.scss";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
export interface AccordionListBlockProps {
  list: ContentAreaProps;
  title: string;
}
const AccordionListBlock: React.FC<AccordionListBlockProps> = ({
  list,
  title,
}) => {
  return (
    <div className={styles.accordionListBlock} id="accordionGroup">
      {title && <h2>{title}</h2>}
      <ContentArea {...list} isInsideBlock noStyling />
    </div>
  );
};
export default AccordionListBlock;
