//NOTE: Copy and paste from MIKA to just have *something* in the components folder. Should be removed or refactored for IMDI later

export interface IMDICtaBlockProps {
  title?: string;
}

const IMDICtaBlock: React.FC<IMDICtaBlockProps> = ({ title }) => {
  return <h1>Hello</h1>;
};

export default IMDICtaBlock;
