import React, { useEffect, useRef } from "react";
import styles from "./MangfoldsTestSlideInfo.module.scss";
import { ReactComponentPropsBase } from "src/base-props/ReactComponentPropsBase";
import classNames from "classnames";
import AnimateIn from "components/utils/AnimateIn/AnimateIn";
import { LinkProps } from "src/base-props/LinkProps";
import Icon from "components/utils/Icon/Icon";
import Button from "components/base/Button/Button";

export interface ResultLinkProps extends LinkProps {
  type: string;
}
export interface MangfoldsTestSlideInfoProps extends ReactComponentPropsBase {
  text: string;
  buttonText?: string;
  onClick?: () => void;
  iconNames?: string[];
  resultLink?: ResultLinkProps;
  isStartSlide?: boolean;
  mangfoldstestenIllustrationLabel?: string;
}

const MangfoldsTestSlideInfo: React.FC<MangfoldsTestSlideInfoProps> = ({
  onClick,
  text,
  buttonText,
  iconNames,
  resultLink,
  isStartSlide,
  mangfoldstestenIllustrationLabel,
}) => {
  const randomNumber = (max: number) => {
    return Math.floor(Math.random() * max);
  };
  const focusStartPoint = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (focusStartPoint && focusStartPoint.current) {
      focusStartPoint.current.focus();
    }
  }, []);

  useEffect(() => {
    const letters = document.getElementsByClassName("letterIllustration");
    for (let i = 0; i < letters.length; i++) {
      const letter = letters[i] as HTMLElement;
      const letterSVG = letter.querySelector("svg") as SVGSVGElement | null;
      if (letterSVG) {
        const animationName = `float-${i}`;
        createKeyframeAnimation(animationName, randomNumber(20) - 10);
        letterSVG.style.animation = `${animationName} 6s ease-in-out infinite`;
        letterSVG.style.animationDelay = `-${randomNumber(5)}s`;
      }
    }
  }, []);

  const createKeyframeAnimation = (name: string, angle: number) => {
    const styleSheet = document.createElement("style");
    styleSheet.innerHTML = `
      @keyframes ${name} {
        0%, 100% {
          transform: translateY(0) scale(1) rotate(${angle}deg);
        }
        50% {
          transform: translateY(-15px) scale(1.03) rotate(${angle}deg);
        }
      }
    `;
    document.head.appendChild(styleSheet);
  };

  return (
    <div className={styles.mangfoldsTestSlideInfo}>
      {iconNames && (
        <>
          {isStartSlide || iconNames.length == 1 ? (
            <AnimateIn>
              {isStartSlide && (
                <span className={styles.visuallyHidden}>
                  {mangfoldstestenIllustrationLabel}
                </span>
              )}
              <Icon
                className={
                  styles[isStartSlide ? "startIllustration" : "illustration"]
                }
                name={iconNames[0]}
                aria-hidden="true"
              />
            </AnimateIn>
          ) : (
            <div className={styles["illustrationContainer"]} aria-hidden={true}>
              {iconNames.map(iconName => (
                <AnimateIn>
                  <Icon
                    className={classNames(
                      styles["illustration"],
                      "letterIllustration"
                    )}
                    name={iconName}
                  />
                </AnimateIn>
              ))}
            </div>
          )}
        </>
      )}

      <AnimateIn delay={0.15}>
        <p
          className={classNames(styles.text)}
          ref={focusStartPoint}
          tabIndex={-1}
        >
          {text}
        </p>
      </AnimateIn>
      {buttonText && !resultLink && (
        <AnimateIn delay={0.25} className={styles.action}>
          <Button onClick={onClick} theme="primary">
            {buttonText}
          </Button>
        </AnimateIn>
      )}
      {resultLink && (
        <AnimateIn delay={0.25} className={styles.action}>
          <Button
            className={styles.resultLink}
            isFakeButton={true}
            theme="primary"
          >
            <a href={resultLink.url}>{resultLink.text}</a>
          </Button>
        </AnimateIn>
      )}
    </div>
  );
};

export default MangfoldsTestSlideInfo;
