import styles from "./ImageBlock.module.scss";
import Image, { ImageProps } from "components/base/Image/Image";

export interface ImageBlockProps {
  byline?: string;
  description?: string;
  image: ImageProps;
}

const ImageBlock: React.FC<ImageBlockProps> = ({
  byline,
  description,
  image,
}) => {
  return (
    <div className={styles.imageBlock}>
      {image && (
        <Image
          {...image}
          sizes={{
            sm: 350,
            md: 430,
            lg: 450,
            xl: 650,
          }}
        />
      )}
      <div className={styles.imageText}>
        {description && <p>{description}</p>}
        {byline && <p>{byline}</p>}
      </div>
    </div>
  );
};

export default ImageBlock;
