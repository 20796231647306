import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import React from "react";
import classNames from "classnames";
import styles from "./MultiColumn.module.scss";
import { GridContainer, GridItem } from "SiteComponents";
import { GridOverrideContext } from "src/utils/GridOverrideContext";
import { GridItemStyleProps } from "../Grid/GridItem";
interface ColumnProps extends MultiColumnProps {
  gridStyle?: GridItemStyleProps;
  nestedGridStyle?: GridItemStyleProps;
}
interface MultiColumnProps extends ReactComponentPropsBase {
  className?: string;
  children: React.ReactNode;
}

const MultiColumn: React.FC<MultiColumnProps> & {
  Left: React.FC<ColumnProps>;
  Right: React.FC<ColumnProps>;
} = ({ children }) => {
  return (
    <GridContainer className={styles.multiColumn} noHorizontal>
      {children}
    </GridContainer>
  );
};

MultiColumn.Left = ({
  children,
  className,
  gridStyle = {},
  nestedGridStyle = {
    lgOffset: (currentValue: number) =>
      currentValue > 0 ? currentValue - 1 : currentValue,
  },
}) => {
  return (
    <GridItem
      {...gridStyle}
      className={classNames(styles.leftColumn, className)}
    >
      <GridOverrideContext.Provider value={nestedGridStyle}>
        {children}
      </GridOverrideContext.Provider>
    </GridItem>
  );
};

MultiColumn.Right = ({
  children,
  className,
  gridStyle = { lgOffset: 1, lg: 3 },
  nestedGridStyle = {},
}) => {
  return (
    <GridItem
      {...gridStyle}
      className={classNames(styles.rightColumn, className)}
    >
      <GridOverrideContext.Provider value={nestedGridStyle}>
        {children}
      </GridOverrideContext.Provider>
    </GridItem>
  );
};

export default MultiColumn;
