import styles from "./SearchInput.module.scss";
import classNames from "classnames";
import { ReactComponentPropsBase } from "../../../../base-props/ReactComponentPropsBase";
import Button from "../../../base/Button/Button";
import Icon from "components/utils/Icon/Icon";
import { useEffect, useRef, useState } from "react";
import { Results } from "../SearchResults/SearchResults";
import IconMS from "src/components/utils/IconMS/IconMS";

export interface SearchHitInfo {
  firstPart?: string;
  lastPart?: string;
}
export interface SearchResponse {
  results?: Results[];
  totalCount?: number;
  query?: string;
}

export interface SearchInputProps extends ReactComponentPropsBase {
  label?: string;
  placeholder?: string;
  buttonText?: string;
  searchHitInfo?: SearchHitInfo;
  search?: (query: string) => void;
  searchWord?: string;
  totalCount?: number;
}

const SearchInput: React.FC<SearchInputProps> = ({
  label,
  placeholder,
  buttonText,
  searchHitInfo,
  search,
  searchWord,
  totalCount,
}) => {
  const [inputValue, setInputValue] = useState(searchWord || "");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const handleClear = () => {
    setInputValue("");
    inputRef?.current?.focus();
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    search && search(inputValue);
  };

  const searchHitInfoLastPart = searchHitInfo?.lastPart?.replace(
    /{.*}/,
    totalCount?.toString() || ""
  );

  return (
    <form onSubmit={handleSubmit} className={styles.searchInput}>
      <label htmlFor="searchInput">{label}</label>
      <div className={styles.inputContainer}>
        <div className={styles.input}>
          <input
            id="searchInput"
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            placeholder={placeholder}
          />
          <button
            className={classNames(
              styles.clearButton,
              inputValue.length < 3 ? styles.showClearButton : null
            )}
            onClick={handleClear}
            type="button"
          >
            <Icon name="close" />
          </button>
        </div>
        <Button
          disabled={inputValue.length < 3 ? true : false}
          className={styles.submit}
        >
          <IconMS name="search" />
          {buttonText}
        </Button>
      </div>
      {searchHitInfo && searchWord && (
        <div className={styles.searchHitInfo}>
          {searchHitInfo.firstPart}
          <span className={styles.searchWord}>&nbsp;{searchWord}&nbsp;</span>
          {searchHitInfoLastPart}
        </div>
      )}
    </form>
  );
};

export default SearchInput;
