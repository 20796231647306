import React from "react";
import styles from "./VideoBlock.module.scss";
import Video, { VideoProps } from "../../../components/shared/Video/Video";

export interface VideoBlockProps {
  video: VideoProps;
}

const VideoBlock: React.FC<VideoBlockProps> = ({ video }) => {
  if (!video) return null;

  return (
    <div className={styles.videoBlock}>
      <div className={styles.blockContainer}>
        <Video {...video} />
      </div>
    </div>
  );
};

export default VideoBlock;
