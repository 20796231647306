import React, { useEffect, useRef, useState } from "react";
import styles from "./RelatedArticlesBlock.module.scss";
import BlockContainer from "../../../components/layout/BlockContainer/BlockContainer";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import { Icon } from "../../../MIKA.Components";
import RelatedArticlesCard, {
  RelatedArticlesCardProps,
} from "./RelatedArticlesCard";
import useWindowSize from "../../../utils/useWindowSize";
import classNames from "classnames";
import useSwipe from "../../../utils/useSwipe";
import useSelectTheme from "../../../utils/useSelectTheme";

export interface RelatedArticlesBlockProps extends ReactComponentPropsBase {
  heading: string;
  cardList: RelatedArticlesCardProps[];
  theme?: "white" | "green";
  labels?: {
    ariaPrevious?: string;
    ariaNext?: string;
  };
}

const RelatedArticlesBlock: React.FC<RelatedArticlesBlockProps> = ({
  heading,
  cardList,
  theme = "white",
  labels,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const [visibleCards, setVisibleCards] = useState(1);
  const [gap, setGap] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const cardsContainerRef = useRef<HTMLDivElement>(null);
  const cardRefs = useRef<HTMLDivElement[]>([]);

  const windowSize = useWindowSize();

  const [touchActive, setTouchActive] = useState(false);
  const { handleTouchStart, handleTouchMove, handleTouchEnd } = useSwipe(
    cardList,
    currentSlide,
    setCurrentSlide,
    cardWidth,
    gap,
    visibleCards,
    setTranslateX,
    touchActive,
    setTouchActive
  );

  useEffect(() => {
    // Get the card width from the DOM
    if (cardRefs.current[0]) {
      const slideWidth = cardRefs.current[0].getBoundingClientRect().width;
      setCardWidth(slideWidth);
    }
    // Get the number of visible cards from the DOM
    if (cardsContainerRef.current) {
      const containerWidth =
        cardsContainerRef.current.getBoundingClientRect().width;
      const slideWidth = cardRefs.current[0].getBoundingClientRect().width;
      setVisibleCards(Math.max(Math.floor(containerWidth / slideWidth), 1));
      const gap = window
        .getComputedStyle(cardsContainerRef.current)
        .getPropertyValue("gap");
      //extract number from gap
      const gapNumber = parseInt(gap.split(" ")[0]);
      setGap(gapNumber);
    }
  }, [cardRefs, cardWidth, windowSize]);

  const moveRight = () => {
    // Don't go past the rightmost slide
    if (currentSlide < cardList?.length - visibleCards) {
      const nextSlide = currentSlide + visibleCards;
      setCurrentSlide(nextSlide);
      setTranslateX(nextSlide * (cardWidth + gap));
    }
  };

  const moveLeft = () => {
    // Don't go past the leftmost slide
    if (currentSlide > 0) {
      const nextSlide = currentSlide - visibleCards;
      setCurrentSlide(nextSlide);
      setTranslateX(nextSlide * (cardWidth + gap));
    }
  };

  const cardTheme = theme === "green" ? "white" : "green";

  const selectedTheme = useSelectTheme(theme, "blue");
  return (
    <div
      className={classNames(styles.relatedArticlesBlock, styles[selectedTheme])}
    >
      <BlockContainer verticalPadding>
        <div className={styles.header}>
          <h2>{heading}</h2>
          <div className={styles.navigation}>
            <button onClick={moveLeft} aria-label={labels?.ariaPrevious}>
              <Icon name="arrow-left" />
            </button>
            <button onClick={moveRight} aria-label={labels?.ariaNext}>
              <Icon name="arrow-right" />
            </button>
          </div>
        </div>
        {cardList && (
          <div
            className={styles.cards}
            style={{
              transform: `translateX(-${translateX}px)`,
              transition: touchActive
                ? "transform 0.1s ease"
                : "transform 0.3s ease",
            }}
            ref={cardsContainerRef}
            aria-live="polite"
            role="region"
            aria-label={heading}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {cardList.map((card, index) => (
              <div key={index} ref={el => el && (cardRefs.current[index] = el)}>
                <RelatedArticlesCard {...card} theme={cardTheme} />
              </div>
            ))}
          </div>
        )}
      </BlockContainer>
    </div>
  );
};

export default RelatedArticlesBlock;
