import React from "react";
import styles from "./AccordionItemBlock.module.scss";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import CTA, { CTAProps } from "components/base/CTA/CTA";
import AccordionBase from "../../utils/Accordion/AccordionBase";
import ProgramItemBlock, {
  ProgramItemBlockProps,
} from "../ProgramItemBlock/ProgramItemBlock";

export interface AccordionItemBlockProps {
  closeLabel?: string;
  heading: string;
  linkButton?: CTAProps;
  openLabel?: string;
  text?: ContentAreaProps;
  program?: ProgramItemBlockProps[];
}

const AccordionItemBlock: React.FC<AccordionItemBlockProps> = ({
  closeLabel,
  heading,
  linkButton,
  openLabel,
  text,
  program,
}) => {
  return (
    <AccordionBase {...{ closeLabel, heading, openLabel, isGlobal: true }}>
      <div className={styles.accordionItemBlock}>
        {program &&
          program.map((event, index) => (
            <ProgramItemBlock key={index} {...event} />
          ))}
        <div className={styles.richText}>
          {text && <ContentArea isInsideBlock {...text} />}
        </div>
        {linkButton && (
          <CTA
            className={styles.linkButton}
            size="small"
            theme="primary"
            {...linkButton}
          />
        )}
      </div>
    </AccordionBase>
  );
};

export default AccordionItemBlock;
