//NOTE: Copy and paste from MIKA to just have *something* in the components folder. Should be removed or refactored for IMDI later

export interface InProCtaBlockProps {
  title?: string;
}

const InProCtaBlock: React.FC<InProCtaBlockProps> = ({ title }) => {
  return <h1>Hello</h1>;
};

export default InProCtaBlock;
