import React, { useEffect, useState } from "react";
import styles from "./FeaturedPersonBlockContactInfo.module.scss";
import classNames from "classnames";
import useWindowSize from "../../../utils/useWindowSize";
import { LinkProps } from "src/base-props/LinkProps";

export interface FeaturedPersonBlockContactInfoProps {
  email: LinkProps,
  phoneNumber: LinkProps,
  showInformation: string;
}

const FeaturedPersonBlockContactInfo: React.FC<
  FeaturedPersonBlockContactInfoProps
> = ({ showInformation, email, phoneNumber }) => {
  const [toggleInformation, setToggleInformation] = useState(false);
  const [contactInfoContainerHeight, setContactInfoContainerHeight] =
    useState(0);

  const contactInfoRef = React.useRef<HTMLDivElement>(null);
  const showInformationRef = React.useRef<HTMLDivElement>(null);

  const handleToggleInformation = () => {
    setToggleInformation(!toggleInformation);
  };

  const windowSize = useWindowSize();

  useEffect(() => {
    if (!contactInfoRef.current || !showInformationRef.current) return;
    const contactInfoHeight = contactInfoRef.current.clientHeight;
    const buttonHeight = showInformationRef.current.clientHeight;

    const heighestElement = Math.max(contactInfoHeight, buttonHeight);
    setContactInfoContainerHeight(heighestElement);
  }, [windowSize]);

  return (
    <button
      className={classNames(styles.featuredPersonBlockContactInfoContainer, {
        [styles.showContactInfo]: toggleInformation,
      })}
      style={{ height: contactInfoContainerHeight }}
      onClick={handleToggleInformation}
    >
      <div className={styles.showInformation} ref={showInformationRef}>
        {showInformation}
      </div>
      <div ref={contactInfoRef} className={styles.contactInfo}>
        {email && <a href={`mailto:${email.url}`}>{email.text}</a>}
        {phoneNumber && (
          <a href={`tel:${phoneNumber.url}`}>{phoneNumber.text}</a>
        )}
      </div>
    </button>
  );
};

export default FeaturedPersonBlockContactInfo;
