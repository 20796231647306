import React, { useState, useEffect } from "react";
import styles from "./Header.module.scss";
import classNames from "classnames";

import AlertBanner, {
  AlertBannerProps,
} from "components/layout/AlertBanner/AlertBanner";
import BypassBanner, {
  BypassBannerProps,
} from "components/layout/BypassBanner/BypassBanner";
import { useTheme } from "../../../utils/useTheme";
import { useSite } from "../../../utils/useSite";
import HeaderLogo from "./HeaderLogo";
import Menu, { MenuProps } from "./Menu";
import NavBar from "./NavBar";
import BlockContainer from "../BlockContainer/BlockContainer";
import FocusTrap from "components/utils/FocusTrap/FocusTrap";
import { LinkProps } from "src/base-props/LinkProps";
import useOnResize from "src/utils/useOnResize";

export interface HeaderProps {
  alertBanner?: AlertBannerProps;
  ariaLinkLabel?: string;
  bypassBanner?: BypassBannerProps;
  hasBreadcrumbs?: boolean;
  layoutTheme?: string;
  loginLink?: LinkProps;
  menuLabel?: string;
  navigation?: MenuProps;
  searchPageLink?: LinkProps;
}

const ALERT_BANNER_ID = "alert-banner-container";
const Header: React.FC<HeaderProps> = ({
  alertBanner,
  ariaLinkLabel,
  bypassBanner,
  loginLink,
  menuLabel,
  navigation,
  searchPageLink,
}) => {
  const site = useSite();

  const theme = useTheme();

  const [bannerHeight, setBannerHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [hideHeader, setHideHeader] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [shouldClose, setShouldClose] = useState(false);
  const entireHeaderHeight = headerHeight + bannerHeight;

  const headerRef = React.useRef<HTMLDivElement>(null);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const disableHideOnScroll = React.useRef(false);

  const handleBannerHeight = (newHeight: number) => {
    disableHideOnScroll.current = true;
    setBannerHeight(newHeight);
    setTimeout(() => {
      disableHideOnScroll.current = false;
    }, 500);
  };

  useOnResize(() => {
    setHeaderHeight(headerRef?.current?.clientHeight || 0);
  });

  useEffect(() => {
    setHeaderHeight(headerRef?.current?.clientHeight || 0);
  }, []);

  useEffect(() => {
    let lastScroll = 0;

    const handleScroll = () => {
      if (disableHideOnScroll.current) return;
      const currentScroll = Math.max(window.scrollY, 0);
      const didScrollUp = currentScroll < lastScroll;
      const didScrollDown = currentScroll > lastScroll;

      if (currentScroll > 200 || didScrollUp) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }

      if (didScrollDown && !isOpen) {
        setHideHeader(true);
      } else {
        setHideHeader(false);
      }

      lastScroll = currentScroll;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isOpen]);

  const handleHamburgerClick = () => {
    if (isOpen) {
      setShouldClose(true);
    } else {
      setIsOpen(true);
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
    setShouldClose(false);
  };

  const allLanguages = navigation?.languages?.availableLanguages;
  const currentLanguage = navigation?.languages?.currentLanguage;
  const currentLanguageCode = allLanguages?.find(
    lang => lang.languageName === currentLanguage
  )?.languageCode;

  useEffect(() => {
    // Set off custom event for header height to be used by other components
    // who's styled depends on the header height
    const heightData = {
      detail: { headerHeight: hideHeader ? 0 : entireHeaderHeight },
    };
    document.dispatchEvent(new CustomEvent("headerResize", heightData));
  }, [bannerHeight, headerHeight, hideHeader]);

  const enableFocusTrap = isOpen || shouldClose;
  return (
    <header
      className={classNames(styles.header, {
        [styles.hasDarkBackground]: ["green", "dark"].includes(theme),
      })}
      //@ts-ignore CSS variable
      style={{ "--headerHeight": entireHeaderHeight + "px" }}
    >
      <FocusTrap
        enable={enableFocusTrap}
        excludeSelectors={[`#${ALERT_BANNER_ID}`]}
      >
        <div
          className={classNames(styles.headerContent, {
            [styles.hideHeader]: isActive && hideHeader,
            [styles.active]: isActive,
            [styles.withOpenMenu]: isOpen,
          })}
        >
          {alertBanner?.message && (
            <div id={ALERT_BANNER_ID}>
              <AlertBanner
                {...alertBanner}
                setBannerHeight={handleBannerHeight}
              />
            </div>
          )}
          {!isOpen && bypassBanner?.message && (
            <BypassBanner {...bypassBanner} />
          )}
          <div ref={headerRef}>
            <BlockContainer className={styles.headerContainer}>
              <HeaderLogo
                {...{ ariaLinkLabel, currentLanguageCode, site, theme, isOpen }}
              />

              <NavBar
                {...{
                  handleHamburgerClick,
                  isOpen,
                  loginLink,
                  navigation,
                  searchPageLink,
                  site,
                  theme,
                  menuLabel,
                  dropdownRef,
                }}
              />
            </BlockContainer>
          </div>
        </div>
        {(isOpen || shouldClose) && (
          <Menu
            shouldCloseMenu={shouldClose}
            closeMenu={closeMenu}
            loginLink={loginLink}
            offsetTop={entireHeaderHeight}
            {...navigation}
            dropdownRef={dropdownRef}
          />
        )}
      </FocusTrap>
    </header>
  );
};

export default Header;
