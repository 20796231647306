import HeroBlock, {
  HeroBlockProps,
} from "../../components/blocks/HeroBlock/HeroBlock";
import { ReactComponentPropsBase } from "../../base-props/ReactComponentPropsBase";
import ContentArea, {
  ContentAreaProps,
} from "../../components/utils/ContentArea/ContentArea";
import useSearchTermHighlight from "src/utils/useHighlightSearchTerm";
import { useTheme } from "src/utils/useTheme";

export interface SectionPageProps extends ReactComponentPropsBase {
  hero?: HeroBlockProps;
  contentArea: ContentAreaProps;
}

const MIKASectionPage: React.FC<SectionPageProps> = ({
  hero,
  contentArea,
}) => {
  const theme = useTheme();
  useSearchTermHighlight("section-page", theme);
  return (
    <main id="section-page">
      {hero && <HeroBlock {...hero} />}
      {contentArea && <ContentArea {...contentArea} />}
    </main>
  );
};

export default MIKASectionPage;
