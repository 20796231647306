import classname from "classnames";
import styles from "./LinkCardSmallBlock.module.scss";
import { Icon } from "SiteComponents";
import { useContext } from "react";
import { AccordionBlockContext } from "../../../components/blocks/AccordionBlock/AccordionBlock";
import { LinkProps } from "src/base-props/LinkProps";
import MouseLink from "components/utils/MouseLink/MouseLink";

export interface LinkCardSmallBlockProps extends LinkProps {
  icon?: string;
  ingress?: string;
  theme?: "primary" | "secondary";
}

const LinkCardSmallBlock: React.FC<LinkCardSmallBlockProps> = ({
  text,
  icon,
  ingress,
  theme = "primary",
  url,
  target,
}) => {
  const { accordionTheme } = useContext(AccordionBlockContext) || {};

  const themeClass = (function () {
    switch (accordionTheme) {
      case "primary":
        return "secondary";
      case "secondary":
        return "primary";
      default:
        return theme;
    }
  })();

  if (url && text) {
    return (
      <MouseLink
        className={classname(styles.linkCardSmallBlock, styles[themeClass])}
        url={url}
      >
        <div className={styles.container}>
          <div>
            <a className={styles.heading} href={url} target={target}>
              {text}
            </a>
            {ingress && <p className={styles.ingress}>{ingress}</p>}
          </div>
          {target === "_blank" ? (
            <Icon className={styles.icon} name="arrow-diagonal" aria-hidden />
          ) : (
            icon && <Icon className={styles.icon} name={icon} aria-hidden />
          )}
        </div>
      </MouseLink>
    );
  }
  return null;
};
export default LinkCardSmallBlock;
