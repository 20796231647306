import { ContactBlockItem } from "SiteComponents";
import styles from "./ContactBlock.module.scss";
import classNames from "classnames";
import { ContactBlockItemProps } from "./ContactBlockItem/ContactBlockItem";

export interface ContactBlockProps {
  heading?: string;
  contactBlockList: ContactBlockItemProps[];
  columns?: number;
  className?: string;
}

const ContactBlock = ({
  heading,
  contactBlockList,
  columns,
  className,
}: ContactBlockProps) => {
  return (
    <div className={classNames(styles.contactBlock, className)}>
      {heading && <h2 className={styles.heading}>{heading}</h2>}
      {contactBlockList && (
        <ul
          className={classNames(styles.list, {
            [styles.columns2]: columns === 2,
            [styles.columns3]: columns === 3,
          })}
        >
          {contactBlockList.map((contactBlockItem, index) => (
            <li key={contactBlockItem.name + index}>
              <ContactBlockItem {...contactBlockItem} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ContactBlock;
