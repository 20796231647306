import styles from "./CenteredCtaBlock.module.scss";
import classNames from "classnames";
import { CTA } from "../../../MIKA.Components";
import useSelectTheme from "../../../utils/useSelectTheme";
import Image, { ImageProps } from "../../../components/base/Image/Image";
import { LinkProps } from "src/base-props/LinkProps";

export interface CenteredCtaBlockProps extends LinkProps {
  buttonText: string;
  heading: string;
  image?: ImageProps;
  showArrow: boolean;
  theme?: "green" | "gray" | "white";
}

const CenteredCtaBlock: React.FC<CenteredCtaBlockProps> = ({
  buttonText,
  heading,
  image,
  showArrow,
  target,
  text,
  theme = "white",
  url,
}) => {
  const selectedTheme = useSelectTheme(theme, "blue");

  if (text && url) {
    return (
      <div
        className={classNames(styles.centeredCtaBlock, styles[selectedTheme])}
      >
        {image && <Image src={image.src} alt={image.alt} />}
        <h2>{text}</h2>
        <div className={styles.tag}>{heading}</div>

        <CTA url={url} target={target} text={buttonText} showIcon={showArrow} />
      </div>
    );
  }
  return null;
};

export default CenteredCtaBlock;
