import { useEffect, RefObject } from "react";

const useClickOutside = (
  refs: RefObject<HTMLDivElement | undefined>[],
  callback: () => void,
  isActive: boolean = true
) => {
  useEffect(() => {
    if (!isActive) return;

    const handleClick = (e: MouseEvent): void => {
      let isOutside = true;
      refs.forEach(ref => {
        if (ref.current && ref.current.contains(e.target as Node)) {
          isOutside = false;
        }
      });

      if (isOutside) {
        callback();
      }
    };

    document.addEventListener("mouseup", handleClick);

    return () => {
      document.removeEventListener("mouseup", handleClick);
    };
  }, [refs, callback, isActive]);
};

export default useClickOutside;
