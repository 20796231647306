import { ThemeContext } from "src/utils/useTheme";

export interface OverrideThemeProps {
  theme?: "light" | "dark";
  children: React.ReactNode;
}

const OverrideTheme: React.FC<OverrideThemeProps> = ({
  theme = "light",
  children,
}) => {
  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
};

export default OverrideTheme;
