import { ReactComponentPropsBase } from "src/base-props/ReactComponentPropsBase";
import styles from "./ContentAnchors.module.scss";
import { IconMS } from "SiteComponents";

export interface ContentAnchorsHeadingProps extends ReactComponentPropsBase {
  isOpen?: boolean;
  toggleAccordion: () => void;
  heading?: string;
  shouldClose?: boolean;
  setShouldClose: (flag: boolean) => void;
}

const ContentAnchorsHeading: React.FC<ContentAnchorsHeadingProps> = ({
  isOpen,
  toggleAccordion,
  heading,
  shouldClose,
  setShouldClose,
}) => {
  if (shouldClose) {
    isOpen && toggleAccordion();
    setShouldClose(false);
  }

  return (
    <>
      <h2 className={styles.heading}>{heading}</h2>
      <IconMS className={styles.headingIcon} name={isOpen ? "remove" : "add"} />
    </>
  );
};

export default ContentAnchorsHeading;
