import React from "react";
import NyINorgeStartPageHeroBlock, {
  NyINorgeStartPageHeroBlockProps,
} from "ny-i-norge/blocks/NyINorgeStartPageHeroBlock/NyINorgeStartPageHeroBlock";
import { ReactComponentPropsBase } from "../../base-props/ReactComponentPropsBase";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";

export interface StartPageProps extends ReactComponentPropsBase {
  hero?: NyINorgeStartPageHeroBlockProps;
  contentArea: ContentAreaProps;
}

const StartPage: React.FC<StartPageProps> = ({ contentArea, hero }) => {
  return (
    <main className="startPage">
      {hero && <NyINorgeStartPageHeroBlock {...hero} />}
      {contentArea && <ContentArea {...contentArea} />}
    </main>
  );
};

export default StartPage;
