import classNames from "classnames";
import styles from "./Tab.module.scss";
import { IconMS } from "SiteComponents";

export interface TabProps {
  id: string;
  className?: string;
  label: string;
  size?: "small" | "medium" | "large";
  icon?: "list" | "marker";
  setActiveTab?: (id: string) => void;
  activeTab?: string;
}

export const Tab = ({
  label,
  id,
  className,
  size = "small",
  icon,
  activeTab,
  setActiveTab = () => {},
}: TabProps) => {
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <li>
      <button
        className={classNames(styles.tab, className, {
          [styles.active]: activeTab === id,
          [styles[size]]: size,
        })}
        onClick={handleClick}
      >
        {icon === "list" && <IconMS name="format_list_bulleted" />}
        {label}
        {icon === "marker" && <span className={styles.marker} />}
      </button>
    </li>
  );
};

export default Tab;
