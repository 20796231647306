import React from "react";
import Media from "../../utils/Media/Media";
import styles from "./MultiMediaBlock.module.scss";
import classNames from "classnames";
import { ImageProps } from "components/base/Image/Image";
import { VideoProps } from "components/shared/Video/Video";
import { useExpandableContent } from "src/utils/useExpandableContent";

export interface MultiMediaBlockProps {
  video?: VideoProps;
  image?: ImageProps;
  byline?: string;
  description?: string;
  expandButtonText?: string;
  closeButtonText?: string;
  isLargeMedia?: boolean;
  onDarkBackground?: boolean;
}

const MultiMediaBlock: React.FC<MultiMediaBlockProps> = ({
  video,
  image,
  byline,
  description,
  expandButtonText,
  closeButtonText,
  onDarkBackground,
}) => {
  const { isExpanded, isTruncated, contentRef, setIsExpanded } =
    useExpandableContent();

  return (
    <div
      className={classNames(styles.multiMediaBlock, {
        [styles.dark]: onDarkBackground === true,
      })}
    >
      {(video || image) && (
        <Media className={styles.media} video={video} image={image} />
      )}
      <div
        className={classNames(styles.imageText, {
          [styles.isExpanded]: isExpanded,
          [styles.isCollapsed]: !isExpanded,
        })}
        ref={contentRef}
      >
        {description && <p className={styles.description}>{description}</p>}
        {byline && <p className={styles.byline}>{byline}</p>}
      </div>
      {expandButtonText && closeButtonText && isTruncated && (
        <button
          className={styles.expandButton}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? closeButtonText : expandButtonText}
        </button>
      )}
    </div>
  );
};

export default MultiMediaBlock;
