import styles from "./CardListBlock.module.scss";
import { LinkProps } from "src/base-props/LinkProps";
import CTA from "../../base/CTA/CTA";
import { CardList, OverrideTheme } from "SiteComponents";
import { CardListProps } from "components/shared/CardList/CardList";

export interface CardListBlockProps extends CardListProps {
  link: LinkProps;
  title: string;
}

const CardListBlock: React.FC<CardListBlockProps> = ({
  link,
  listItems,
  title,
  type,
  theme,
}) => {
  return (
    <div className={styles.cardListBlock}>
      <div className={styles.cardListBlockText}>
        {title && <h2>{title}</h2>}
        {link && (
          <OverrideTheme theme="light">
            <CTA theme="tertiary" size="small" {...link} />
          </OverrideTheme>
        )}
      </div>
      <CardList listItems={listItems} type={type} theme={theme} />
    </div>
  );
};

export default CardListBlock;
