import GlobalCardBlock, {
  GlobalCardBlockProps,
} from "../GlobalCardBlock/GlobalCardBlock";
import styles from "./SingleCardBlock.module.scss";

interface SingleCardBlockProps {
  heading: string;
  card: GlobalCardBlockProps;
}

const SingleCardBlock = ({ heading, card }: SingleCardBlockProps) => {
  if (!card) return null;
  return (
    <div className={styles.singleCardBlock}>
      {heading && <h3 className={styles.heading}>{heading}</h3>}
      <GlobalCardBlock {...card} />
    </div>
  );
};

export default SingleCardBlock;
