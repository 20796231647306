import { ReactNode } from "react";
import styles from "./BlockContainer.module.scss";
import classNames from "classnames";

export interface BlockContainerProps {
  children: ReactNode;
  className?: string;
  fullWidth?: boolean;
  verticalPadding?: boolean;
  noVerticalSpacing?: boolean;
  horizontalSpacing?: "md" | "lg";
  rowGap?: "md" | "lg" | "xl";
}

const BlockContainer: React.FC<BlockContainerProps> = ({
  children,
  fullWidth,
  className,
  verticalPadding,
  noVerticalSpacing,
  horizontalSpacing = "lg",
  rowGap,
  ...props
}) => {
  return (
    <div
      {...props}
      className={classNames(styles.blockContainer, className, {
        [styles.fullWidth]: fullWidth,
        [styles.verticalPadding]: verticalPadding,
        [styles.noVerticalSpacing]: noVerticalSpacing,
        [styles.horizontalSpacingLarge]: horizontalSpacing === "lg",
        [styles.rowGapMedium]: rowGap === "md",
        [styles.rowGapLarge]: rowGap === "lg",
        [styles.rowGapXLarge]: rowGap === "xl",
      })}
    >
      {children}
    </div>
  );
};

export default BlockContainer;
