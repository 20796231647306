"use client";
import React, { FC } from "react";
import styles from "./ErrorText.module.scss";

interface IErrorTextProps {
  children: React.ReactNode;
}

const ErrorText: FC<IErrorTextProps> = ({ children }) => {
  return <div className={styles.errorText}>{children}</div>;
};

export default ErrorText;
