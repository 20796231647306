import React from "react";
import styles from "./FeaturedPersonBlock.module.scss";
import classNames from "classnames";
import Image, { ImageProps } from "../../../components/base/Image/Image";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import FeaturedPersonBlockContactInfo, {
  FeaturedPersonBlockContactInfoProps,
} from "./FeaturedPersonBlockContactInfo";
import useSelectTheme from "../../../utils/useSelectTheme";

export interface FeaturedPersonBlockProps {
  department: string;
  heading: string;
  image: ImageProps;
  name: string;
  position: string;
  contactInfo: FeaturedPersonBlockContactInfoProps;
}

const FeaturedPersonBlock: React.FC<FeaturedPersonBlockProps> = ({
  department,
  heading,
  image,
  name,
  position,
  contactInfo,
}) => {
  const selectedTheme = useSelectTheme("", "blue");
  return (
    <BlockContainer>
      <div
        className={classNames(
          styles.featuredPersonBlock,
          styles[selectedTheme]
        )}
      >
        <h2>{heading}</h2>
        <div className={styles.details}>
          <Image
            {...image}
            sizes={{
              sm: 115,
              md: 177,
              lg: 177,
              xl: 177,
            }}
          />
          <p className={styles.name}>{name}</p>
          <div>
            <p>{position}</p>
            <p>{department}</p>
          </div>
          {contactInfo && <FeaturedPersonBlockContactInfo {...contactInfo} />}
        </div>
      </div>
    </BlockContainer>
  );
};

export default FeaturedPersonBlock;
