import { useEffect } from "react";

type Callback = () => void;

const useOnResize = (callback: Callback) => {
  useEffect(() => {
    const handleResize = () => {
      callback();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [callback]);
};

export default useOnResize;
