import classNames from "classnames";
import styles from "./CardList.module.scss";
import { GlobalCardBlock } from "SiteComponents";
import { GlobalCardBlockProps } from "components/blocks/GlobalCardBlock/GlobalCardBlock";

export interface CardListProps {
  listItems: GlobalCardBlockProps[];
  type?:
    | "ReportPage"
    | "InfoPage"
    | "EventPage"
    | "TilskuddPage"
    | "GenericPage";
  theme?: "bold" | "moderate" | "outlined" | "soft" | "vivid";
  isInsideTabs?: boolean;
}

const CardList: React.FC<CardListProps> = ({
  listItems,
  type,
  theme,
  isInsideTabs,
}) => {
  const isMedium = type === "TilskuddPage" || type === "GenericPage"
  return (
    <>
      {listItems.length > 0 && (
        <ul
          className={classNames(styles.cardList, {
            [styles.isMedium]: isMedium ,
            [styles.isInsideTabs]: isInsideTabs,
          })}
        >
          {listItems.map((card, index) => (
            <li key={index}>
              <GlobalCardBlock
                isMedium={
                  isMedium
                }
                theme={theme}
                type={type}
                {...card}
              />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default CardList;
