import React from "react";
import Video, { VideoProps } from "components/shared/Video/Video";
import styles from "./HeroBlock.module.scss";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import Image, { ImageProps } from "../../base/Image/Image";
import classNames from "classnames";
import { useSite } from "../../../utils/useSite";
import { useTheme } from "../../../utils/useTheme";
import useHeroBackground from "src/utils/use-hero-background/useHeroBackground";

export interface HeroBlockProps extends ReactComponentPropsBase {
  heading: string;
  mainIntro: string;
  image?: ImageProps;
  videoLink?: VideoProps;
}

const HeroBlock: React.FC<HeroBlockProps> = ({
  heading,
  mainIntro,
  image,
  videoLink: video,
}) => {
  const isWithoutMedia = !image && !video;

  const site = useSite();
  const theme = useTheme();

  const backgroundClass = useHeroBackground();

  return (
    <div
      className={classNames(
        backgroundClass,
        ["dark", "green"].includes(theme) ? styles.green : styles.white
      )}
    >
      <BlockContainer
        className={classNames(styles.heroBlock, {
          [styles.themeMIKA]: site === "mika",
          [styles.themeNyINorge]: site === "ny-i-norge",
        })}
      >
        <div
          className={
            isWithoutMedia ? styles.textContentNoMedia : styles.textContent
          }
        >
          <h1 className={styles.heading}>{heading}</h1>
          <div className={styles.ingressContainer}>
            <p className={styles.ingress}>{mainIntro}</p>
          </div>
        </div>

        {!isWithoutMedia && (
          <div className={styles.mediaContent}>
            {video && <Video {...video} />}
            {image && !video && (
              <Image
                {...image}
                ratio="hero"
                sizes={{
                  sm: 400,
                  md: 480,
                  lg: 768,
                  xl: 942,
                }}
              />
            )}
          </div>
        )}
      </BlockContainer>
    </div>
  );
};

export default HeroBlock;
