import { useRef, useState } from "react";
import { cacheService } from "./cacheService";

export type GenericParams = Record<string, string | undefined | number>;
type CleanParams = Record<string, string>;

const removeUndefined = (params: GenericParams): CleanParams => {
  Object.keys(params || {}).forEach(
    key => params[key] === undefined && delete params[key]
  );

  return params as CleanParams;
};
const useFetchHelper = () => {
  const [isLoading, setIsLoading] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  const abortHelper = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    return abortControllerRef.current.signal;
  };

  const fetchData = async <T>(
    endpoint: string,
    params: GenericParams = {}
  ): Promise<T> => {
    if (!endpoint) throw new Error("No endpoint provided");

    setIsLoading(true);
    const cleanParams = removeUndefined(params);

    const apiUrl = constructUrl(endpoint, cleanParams);
    // Abort the previous request if there is one
    const signal = abortHelper();

    if (params) {
      updateBrowserUrl(new URLSearchParams(cleanParams));
    }
    // Check if the response is already in the cache
    const cachedData = cacheService.get<T>(apiUrl);
    if (cachedData) {
      setIsLoading(false);
      return cachedData;
    }

    try {
      const response = await fetch(apiUrl, { signal });

      if (!response.ok) {
        throw new Error(
          `Error fetching results: ${response.statusText} (Status Code: ${response.status})`
        );
      }

      const data: T = await response.json();
      cacheService.set(apiUrl, data);
      setIsLoading(false);
      return data;
    } catch (error: unknown) {
      if (!(error instanceof Error)) throw error;

      if (error.name === "AbortError") {
        throw "Request aborted";
      } else {
        setIsLoading(false);
        throw error;
      }
    }
  };

  return { fetchData, isLoading };
};

export default useFetchHelper;

function constructUrl(endpoint: string, params?: CleanParams): string {
  let baseUrl = `${window.location.origin}${window.location.pathname}`;
  if (process.env.NODE_ENV === "development") {
    // In dev mode use mock server
    baseUrl = `http://localhost:3111`;
  }

  const url = new URL(endpoint, baseUrl);

  if (params) {
    Object.keys(params).forEach(key =>
      url.searchParams.append(key, params[key].toString())
    );
  }

  return url.toString();
}

function updateBrowserUrl(params: URLSearchParams) {
  // Update browser URL without triggering a full page reload
  const newBrowserUrl = new URL(window.location.href);
  newBrowserUrl.search = params.toString();
  window.history.pushState({}, "", newBrowserUrl.toString());
}
