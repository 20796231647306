import styles from "./MIKAStartPageHeroBlock.module.scss";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import { Icon } from "SiteComponents";
import { useCallback, useEffect, useState } from "react";

export interface MIKAStartPageHeroBlockProps {
  heading: string;
  subHeading?: string;
}

const svgNames = ["hero-desktop_v1", "hero-mobile_v1"];

const MIKAStartPageHeroBlock: React.FC<MIKAStartPageHeroBlockProps> = ({
  heading,
  subHeading,
}) => {
  const [windowWidth, setWindowWidth] = useState(0);
  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  const handleResize = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setWindowWidth(window.innerWidth);
    }, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const getSvgName = useCallback(() => {
    return windowWidth > 1023 ? svgNames[0] : svgNames[1];
  }, [windowWidth]);

  return (
    <div className={styles.heroBlock}>
      <BlockContainer className={styles.heroBlockContainer}>
        <div className={styles.heroBlockContent}>
          <div className={styles.textContainer}>
            <h1>{heading}</h1>
            {subHeading && <h2>{subHeading}</h2>}
          </div>

          <Icon name={getSvgName()} className={styles.heroBlockBackground} />
        </div>
      </BlockContainer>
    </div>
  );
};

export default MIKAStartPageHeroBlock;
