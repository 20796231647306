import styles from "./HighlightedInfoBlock.module.scss";
import RichTextBlock from "../RichTextBlock/RichTextBlock";
import { Button, IconMS } from "SiteComponents";
import { ContentAreaProps } from "components/utils/ContentArea/ContentArea";
import { ReactPropsBase } from "src/base-props/ReactPropsBase";
import { useEffect, useId, useRef, useState } from "react";
import classNames from "classnames";
import { setTabIndexes } from "src/utils/setTabIndexes";

export interface HighlightedInfoBlockProps extends ReactPropsBase {
  text: ContentAreaProps;
  tagline?: string;
  expandButtonText: string;
  collapseButtonText: string;
  theme?: "default" | "outlined" | "variant";
}

const HighlightedInfoBlock: React.FC<HighlightedInfoBlockProps> = ({
  text,
  tagline,
  expandButtonText,
  collapseButtonText,
  theme = "default",
}) => {
  const [expandedHeight, setExpandedHeight] = useState(0);

  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const uniqueId = useId();

  useEffect(() => {
    if (expandButtonText == ""){
      setIsExpanded(true);
    }
  }, [])

  useEffect(() => {
    const richText = contentRef.current;
    if (!richText) return;
    if (isExpanded) {
      setExpandedHeight(richText.scrollHeight);
      setTabIndexes(richText, "0");
    } else {
      setTabIndexes(richText, "-1");
    }
  }, [isExpanded]);

  return (
    <div
      className={classNames(styles.highlightedInfoBlock, {
        [styles.expanded]: isExpanded,
        [styles[theme]]: theme,
      })}
      //@ts-ignore CSS variable
      style={{ "--expandedHeight": expandedHeight + "px" }}
    >
      {tagline && <span className={styles.tagline}>{tagline}</span>}
      {text && (
        <div
          className={styles.textWrapper}
          ref={contentRef}
          id={`hightlighted-info-expandable-content-${uniqueId}`}
          aria-hidden={!isExpanded}
        >
          <RichTextBlock text={text} />
          {!isExpanded && <div className={styles.gradientOverlay}></div>}
        </div>
      )}

      {expandButtonText && collapseButtonText && (
        <Button
          className={styles.expandButton}
          onClick={() => setIsExpanded(prev => !prev)}
          aria-expanded={isExpanded}
          aria-controls={`hightlighted-info-expandable-content-${uniqueId}`}
          theme="primary"
          size="small"
        >
          <IconMS name={isExpanded ? "remove" : "add"} />
          {isExpanded ? collapseButtonText : expandButtonText}
        </Button>
      )}
    </div>
  );
};

export default HighlightedInfoBlock;
