import styles from "./CardBlock.module.scss";
import classNames from "classnames";
import Image, { ImageProps } from "../../base/Image/Image";
import MouseLink from "components/utils/MouseLink/MouseLink";
import { LinkProps } from "src/base-props/LinkProps";

export interface CardBlockProps extends LinkProps {
  image?: ImageProps;
  intro: string;
  theme?: "grey" | "white";
}

const CardBlock: React.FC<CardBlockProps> = ({
  image,
  intro,
  text,
  url,
  theme = "white",
}) => {
  if (text && url) {
    return (
      <MouseLink
        className={classNames(styles.cardBlock, styles[theme])}
        url={url}
      >
        {image && (
          <Image
            {...image}
            sizes={{
              sm: 330,
              md: 420,
              lg: 420,
              xl: 420,
            }}
          />
        )}
        <div className={styles.textContainer}>
          <a href={url}>
            <h2>{text}</h2>
          </a>
          <p>{intro}</p>
        </div>
      </MouseLink>
    );
  }
  return null;
};

export default CardBlock;
