import mitt from "mitt";

// Define a type for your events and their corresponding data types
type Events = {
  search: string; // 'search' event with a string payload
  update: { id: number; value: string }; // 'update' event with an object payload
};

// Create a typed mitt instance
const emitter = mitt<Events>();

export default emitter;
