import { useContext, createContext } from "react";

export const SiteContext = createContext<"mika" | "ny-i-norge" | "imdi" | "inpro" | "nora">(
  "ny-i-norge"
);

export function useSite() {
  const site = useContext(SiteContext);

  if (site === undefined) {
    throw new Error("useSite must be used within a SiteProvider");
  }

  return site;
}
