import React from "react";
import styles from "./ErrorPage.module.scss";
import { ReactComponentPropsBase } from "../../base-props/ReactComponentPropsBase";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import { Icon, ImdiLogo } from "SiteComponents";
import CTA, { CTAProps } from "components/base/CTA/CTA";
import { useSite } from "src/utils/useSite";
import useHeroBackground from "src/utils/use-hero-background/useHeroBackground";
import classNames from "classnames";

export interface ErrorPageProps extends ReactComponentPropsBase {
  heading: string;
  message: string;
  primaryLink: CTAProps;
  secondaryLink?: CTAProps;
  statusCode: number;
  statusText?: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  heading,
  message,
  primaryLink,
  secondaryLink,
  statusCode,
  statusText,
}) => {
  const site = useSite();
  const backgroundClass = useHeroBackground();

  return (
    <main className={styles.errorPage}>
      <div className={backgroundClass}>
        <BlockContainer className={classNames(styles.errorPageBlock, {[styles.serverError]: statusCode === 500})} verticalPadding>
          {statusCode && statusCode !== 500 && site && (
            <Icon
              className={styles.errorPageBlockImage}
              name={`${statusCode}_${site}`}
            />
          )}
          <div className={styles.errorPageBlockText}>
            {statusText && <p className={styles.statusText}>{statusText}</p>}
            <h1>{heading}</h1>
            <p className={styles.message}>{message}</p>
            <div className={styles.buttonContainer}>
              <CTA size="small" {...primaryLink} />
              {secondaryLink && (
                <CTA theme="secondary" size="small" {...secondaryLink} />
              )}
            </div>
            {statusCode === 500 && <a
            href={"https://imdi.no"}
            aria-label={"Lenke til imdi"}
            className={styles.logo}
          >
            <ImdiLogo className={styles.imdiLogo} withText />
          </a>}
          </div>
        </BlockContainer>
      </div>
    </main>
  );
};

export default ErrorPage;
