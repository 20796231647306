import React from "react";
import styles from "./Panel.module.scss";
interface PanelProps {
  id: string;
  children: React.ReactNode;
  activeTab?: string;
}

export const Panel = ({ id, children, activeTab }: PanelProps) => {
  return activeTab === id ? (
    <div className={styles.panel}>{children}</div>
  ) : null;
};

export default Panel;
