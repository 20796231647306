import React, { useEffect, useState } from "react";
import { ReactComponentPropsBase } from "src/base-props/ReactComponentPropsBase";
import { LinkProps } from "src/base-props/LinkProps";
import { MangfoldsTestSlideQuestionProps } from "./MangfoldsTestSlideQuestion/MangfoldsTestSlideQuestion";
import MangfoldsTestSlide from "./MangfoldsTestSlide";
import MangfoldsTestNav from "./MangfoldsTestNav/MangfoldsTestNav";

interface ResultLinkProps extends LinkProps {
  type: string;
}

export interface MangfoldsTestBlockProps extends ReactComponentPropsBase {
  resultLinks: ResultLinkProps[];
  slides: MangfoldsTestSlideQuestionProps[];
  sliderNav: {
    prevText: string;
    homeLinkText: string;
  };
}

const letterFileNames = [
  "mangfoldstesten/letters/01-m",
  "mangfoldstesten/letters/02-a",
  "mangfoldstesten/letters/03-n",
  "mangfoldstesten/letters/04-g",
  "mangfoldstesten/letters/05-f",
  "mangfoldstesten/letters/06-o",
  "mangfoldstesten/letters/07-l",
  "mangfoldstesten/letters/08-d",
  "mangfoldstesten/letters/09-s",
  "mangfoldstesten/letters/10-t",
  "mangfoldstesten/letters/11-e",
  "mangfoldstesten/letters/12-s",
  "mangfoldstesten/letters/13-t",
  "mangfoldstesten/letters/14-e",
  "mangfoldstesten/letters/15-n",
];

const MangfoldsTestBlock: React.FC<MangfoldsTestBlockProps> = ({
  resultLinks,
  slides,
  sliderNav,
}) => {
  const [slidesState, setSlidesState] = useState<
    MangfoldsTestSlideQuestionProps[]
  >(slides.map(slide => ({ ...slide, userAnswer: null })));

  const [currentSlide, setCurrentSlide] = useState(0);
  const [progressIndex, setProgressIndex] = useState(0); //Slide index of next unanswered question
  const [resultLink, setResultLink] = useState<ResultLinkProps | undefined>();
  const [letterArray, setLetterArray] = useState<string[][]>([]);

  const handleAnswer = (answer: string) => {
    setSlidesState(prevState => {
      const newState = [...prevState];
      newState[currentSlide].userAnswer = answer;
      return newState;
    });
  };

  const handleNext = () => {
    // Clamp to last slide
    setCurrentSlide(prev => Math.min(prev + 1, slidesState.length - 1));
    setProgressIndex(prev => Math.min(prev + 1, slidesState.length - 1));
  };

  useEffect(() => {
    const isLastSlide =
      currentSlide === slidesState.length - 1 && slidesState.length > 1;
    if (isLastSlide) {
      const link = calcResultLink(slidesState, resultLinks);
      setResultLink(link);
    }
  }, [slidesState]);

  const activeSlideProps = slidesState[currentSlide];

  const handleCancel = () => {
    // Go to previous page
    window.history.back();
  };

  function distributeLetters(letters: string[], pages: number): string[][] {
    const lettersLength = letters.length;
    const requiredLetters = pages * 2;

    const enoughLetters = lettersLength >= requiredLetters;
    const result: string[][] = [];
    let start = 0;
    let end;

    if (enoughLetters) {
      const lettersPerPage = Math.floor(lettersLength / pages);
      const extraLetters = lettersLength % pages;

      for (let i = 0; i < pages; i++) {
        if (i >= pages - extraLetters) {
          end = start + lettersPerPage + 1;
        } else {
          end = start + lettersPerPage;
        }
        result.push(letters.slice(start, end));
        start = end;
      }
    } else {
      let missingLetters = requiredLetters % lettersLength;
      if (missingLetters === 0) missingLetters = lettersLength;
      if (requiredLetters > lettersLength * 2) {
        const lettersCopy = letters;
        for (let i = 0; i < missingLetters; i++) {
          const randomIndex =
            Math.floor(Math.random() * (lettersCopy.length - 2)) + 1;
          lettersCopy.splice(randomIndex + 1, 0, lettersCopy[randomIndex]);
        }
        missingLetters = requiredLetters % lettersCopy.length;
      }
      for (let i = 0; i < pages; i++) {
        if (i >= pages - missingLetters - 1) {
          end = start + 2;
          result.push(letters.slice(start, end));
          start = end - 1;
        } else {
          end = start + 2;
          result.push(letters.slice(start, end));
          start = end;
        }
      }
    }

    return result;
  }

  useEffect(() => {
    setLetterArray(distributeLetters(letterFileNames, slidesState.length));
  }, []);

  return (
    <>
      <MangfoldsTestSlide
        slideQuestion={activeSlideProps}
        handleNext={handleNext}
        handleAnswer={answer => handleAnswer(answer)}
        currentSlide={currentSlide}
        resultLink={resultLink}
        iconNames={letterArray[currentSlide]}
      />
      <MangfoldsTestNav
        {...sliderNav}
        totalQuestions={slidesState.length}
        currentIndex={currentSlide}
        cancel={handleCancel}
        // next={() => setCurrentSlide(prev => prev + 1)}
        prev={() => setCurrentSlide(prev => prev - 1)}
        isOnPreviousSlide={currentSlide < progressIndex}
      />
    </>
  );
};

export default MangfoldsTestBlock;

function calcResultLink(
  slidesState: MangfoldsTestSlideQuestionProps[],
  resultLinks: ResultLinkProps[]
) {
  const score = slidesState.reduce(
    (scoreAcc, slide) => {
      const userAnswer = slide.userAnswer;
      if (!userAnswer)
        throw new Error(
          `Not all questions have been answered: ${JSON.stringify(
            slide.question
          )}`
        );
      const matchingAnswer = slide.answers.find(
        answer => answer.type === slide.userAnswer
      );
      const matchingAnswerWeight = matchingAnswer?.weight || 1;
      return {
        ...scoreAcc,
        [userAnswer]: (scoreAcc[userAnswer] || 0) + matchingAnswerWeight,
      };
    },
    {} as Record<string, number>
  );

  const highestScore = Object.keys(score).sort(
    (a, b) => score[b] - score[a]
  )[0];
  const link = resultLinks.find(
    result => result.type.toString() === highestScore
  );
  return link;
}
