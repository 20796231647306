import { useTheme } from "./useTheme";

function useSelectTheme<T extends string, U extends string>(
  normalTheme: T,
  mangfoldsprisTheme: U
): T | U {
  const pageTheme = useTheme();

  const whiteBackground = ["white", "dark", "light", "green"].includes(pageTheme);

  if (whiteBackground) {
    return normalTheme;
  } else {
    return mangfoldsprisTheme;
  }
}

export default useSelectTheme;
