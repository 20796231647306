import React, { useEffect, useRef, useState } from "react";
import BlockContainer from "src/components/layout/BlockContainer/BlockContainer";
import useWindowSize from "src/utils/useWindowSize";

export interface FormBlockProps {
  formUrl: string;
}

interface IframeStyle {
  height?: number;
  border: string;
  width: string;
}

const FormBlock: React.FC<FormBlockProps> = ({ formUrl }) => {
  const [urlWithCurrentPage, setUrlWithCurrentPage] = useState<string>("");
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [currentIntervalIndex, setCurrentIntervalIndex] = useState(-1);

  const widthIntervals = [400, 620, 860, 1260, Infinity];

  const windowWidth = useWindowSize().width;

  const [iframeStyle, setIframeStyle] = useState<IframeStyle>({
    border: "none",
    width: "100%",
  });

  useEffect(() => {
    const newIndex = widthIntervals.findIndex(
      interval => windowWidth < interval
    );
    if (newIndex > currentIntervalIndex && currentIntervalIndex !== -1) {
      setIframeStyle({
        ...iframeStyle,
        height: iframeStyle.height! - (newIndex > 3 ? 45 : 80),
      });
    } else if (newIndex < currentIntervalIndex && currentIntervalIndex !== -1) {
      setIframeStyle({
        ...iframeStyle,
        height: iframeStyle.height! + (newIndex > 2 ? 45 : 80),
      });
    }
    setCurrentIntervalIndex(newIndex);
  }, [windowWidth]);

  useEffect(() => {
    const currentPageUrl = window.location.href;
    const parsedUrl = new URL(formUrl);
    parsedUrl.searchParams.set("pageUrl", currentPageUrl);
    setUrlWithCurrentPage(parsedUrl.toString());
  }, [formUrl]);

  useEffect(() => {
    const handleMessages = (message: MessageEvent) => {
      if (!message) {
        return;
      }

      if (typeof message.data !== "string") {
        return;
      }
      const [method, value] = message.data.split(":");

      if (method === "setHeight") {
        setIframeStyle({ ...iframeStyle, height: parseInt(value) + 20 });
      }
    };

    window.addEventListener("message", handleMessages, true);

    return () => {
      window.removeEventListener("message", handleMessages, true);
    };
  }, []);

  return (
    <BlockContainer>
      <iframe
        ref={iframeRef}
        src={urlWithCurrentPage}
        className="jotform-iframe"
        title="jotform Iframe"
        style={{ ...iframeStyle, overflowY: "hidden" }}
      />
    </BlockContainer>
  );
};

export default FormBlock;
