import React, { ReactNode } from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";
import WithActionClasses, { WithActionClassesProps } from "components/HoC/WithActionClasses/WithActionClasses";

export interface BasicButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLDivElement> {
  isFakeButton?: boolean;
  buttonText?: string;
  className?: string;
  disabled?: boolean;
  children?: ReactNode;
}

export interface ButtonProps extends BasicButtonProps, WithActionClassesProps {}

const Button: React.FC<BasicButtonProps> = ({
  isFakeButton = false,
  children,
  className,
  disabled,
  buttonText,
  ...props
}) => {
  const ButtonOrDiv = isFakeButton ? "div" : "button";
  const textOrChildren = children ? children : buttonText;

  return (
    <ButtonOrDiv
      className={classNames(styles.button, className)}
      {...props}
      disabled={disabled}
    >
      {textOrChildren}
    </ButtonOrDiv>
  );
};

export const BasicButton = Button;
export default WithActionClasses(Button);
