import React from "react";
import styles from "./FilterBlock.module.scss";
import { FilterListContainer, FilterVertical } from "SiteComponents";
import { FilterVerticalProps } from "./FilterVertical/FilterVertical";
import { Filter } from "./FilterVertical/FilterList";
import { CheckboxProps } from "components/base/Checkbox/Checkbox";
import { SharedFilterListContainerProps } from "./FilterListContainer/FilterListContainer";

export interface FilterBlockProps extends SharedFilterListContainerProps {
  filterState?: FilterVerticalProps[];
  onFilterChange?: (filters: any) => void;
  handleShowResults?: () => void;
}

const FilterBlock: React.FC<FilterBlockProps> = ({
  heading,
  labels,
  filterState,
  onFilterChange = () => {},
  handleShowResults = () => {},
}) => {
  const handleFilterGroupChange = (
    updatedFilterGroupId: string,
    updatedFilters: CheckboxProps[]
  ) => {
    let didUpdateFilterState = false;

    const newFilterState = filterState?.map(filterGroup => {
      if (filterGroup.id === updatedFilterGroupId) {
        didUpdateFilterState = true;
        return { ...filterGroup, filters: updatedFilters };
      }
      return filterGroup;
    });

    if (didUpdateFilterState) {
      onFilterChange(newFilterState);
    }

    return newFilterState;
  };

  const clearAllFilters = () => {
    const clearedFilterGroupList = filterState?.map(filterGroup => {
      return {
        ...filterGroup,
        filters: (function getClearedFilters(filters: Filter[]): Filter[] {
          return filters.map(filter => ({
            ...filter,
            checked: false,
            nestedFilters: getClearedFilters(filter.nestedFilters || []),
          }));
        })(filterGroup.filters),
      };
    });

    onFilterChange(clearedFilterGroupList);
  };

  return (
    <FilterListContainer
      heading={heading}
      handleClearAll={clearAllFilters}
      labels={labels}
      handleShowResults={handleShowResults}
      filterGroupCount={filterState?.length}
    >
      <ul className={styles.filterList}>
        {filterState?.map(filterGroup => {
          return (
            <li key={filterGroup.id}>
              <FilterVertical
                {...filterGroup}
                filters={filterGroup.filters}
                handleFilterGroupChange={handleFilterGroupChange}
              />
            </li>
          );
        })}
      </ul>
    </FilterListContainer>
  );
};

export default FilterBlock;
