import styles from "./Breadcrumbs.module.scss";
import classNames from "classnames";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import { useTheme } from "../../../utils/useTheme";
import Icon from "components/utils/Icon/Icon";
import useHeroBackground from "src/utils/use-hero-background/useHeroBackground";
import { LinkProps } from "src/base-props/LinkProps";

export interface BreadcrumbsProps {
  breadcrumbs: LinkProps[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  const theme = useTheme();

  const heroBackgroundClass = useHeroBackground();

  return (
    <div
      className={classNames(styles.breadcrumbs, heroBackgroundClass, {
        [styles.hasDarkBackground]: ["green", "dark"].includes(theme),
      })}
    >
      <BlockContainer className={styles.blockContainer}>
        <nav aria-label="breadcrumb">
          <ol className={styles.breadcrumbsList}>
            {breadcrumbs?.map((breadcrumb, index) => {
              const isLast = index === breadcrumbs.length - 1;
              return (
                <li key={breadcrumb.text} className={styles.item}>
                  {!isLast ? (
                    <>
                      <a href={breadcrumb.url}>{breadcrumb.text}</a>
                      <Icon
                        aria-hidden
                        className={styles.triangleIcon}
                        name="triangle-small"
                      />
                    </>
                  ) : (
                    <span>{breadcrumb.text}</span>
                  )}
                </li>
              );
            })}
          </ol>
        </nav>
      </BlockContainer>
    </div>
  );
};

export default Breadcrumbs;
