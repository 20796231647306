import styles from "./SectionPageHeroBlock.module.scss";
import classNames from "classnames";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import MultiMediaBlock from "../MultiMediaBlock/MultiMediaBlock";
import { ImageProps } from "../../../components/base/Image/Image";
import CTA, { CTAProps } from "components/base/CTA/CTA";
import useHeroBackground from "src/utils/use-hero-background/useHeroBackground";
import { useTheme } from "../../../utils/useTheme";

export interface SectionPageHeroBlockProps extends ReactComponentPropsBase {
  byline?: string;
  heading: string;
  image?: ImageProps;
  linkList?: CTAProps[];
  mainIntro: string;
  mediaText?: string;
  showLessText?: string;
  showMoreText?: string;
}

const SectionPageHeroBlock: React.FC<SectionPageHeroBlockProps> = ({
  byline,
  heading,
  image,
  linkList,
  mainIntro,
  mediaText,
  showLessText,
  showMoreText,
}) => {
  const backgroundClass = useHeroBackground();

  const theme = useTheme();
  return (
    <div className={backgroundClass}>
      <BlockContainer
        className={classNames(styles.sectionPageHeroBlock, {
          [styles.withMedia]: image,
          [styles.dark]: theme === "dark",
        })}
        verticalPadding
      >
        {image && (
          <div className={styles.sectionPageHeroBlockMedia}>
            <MultiMediaBlock
              image={image}
              description={mediaText}
              byline={byline}
              closeButtonText={showLessText}
              expandButtonText={showMoreText}
              onDarkBackground={theme === "dark"}
            />
          </div>
        )}
        <div className={styles.sectionPageHeroBlockContent}>
          {heading && <h1>{heading}</h1>}
          {mainIntro && <p className={styles.mainIntro}>{mainIntro}</p>}
          {linkList && (
            <ul>
              {linkList &&
                linkList.map(link => (
                  <li>
                    <CTA {...link} theme="secondary" size="small" />
                  </li>
                ))}
            </ul>
          )}
        </div>
      </BlockContainer>
    </div>
  );
};

export default SectionPageHeroBlock;
