import classNames from "classnames";
import styles from "./HeaderLogo.module.scss";

import { Icon } from "SiteComponents";
import ImdiLogo from "components/base/ImdiLogo/ImdiLogo";

export interface HeaderLogoProps {
  ariaLinkLabel?: string;
  currentLanguageCode?: string;
  isOpen?: boolean;
  site?: string;
  theme?: string;
}

const HeaderLogo: React.FC<HeaderLogoProps> = ({
  ariaLinkLabel,
  currentLanguageCode,
  isOpen,
  site,
  theme = "white",
}) => {
  let logoIconName;

  switch (site) {
    case "mika":
      logoIconName = "mika-logo-v2-2";
      break;
    case "nora":
      logoIconName = "nora-logo";
      break;
    case "ny-i-norge":
      logoIconName = "nyinorge-logo-v2";
      break;
    case "inpro":
      logoIconName = "inpro-logo";
      break;
    default:
      logoIconName = "imdi-logo-v2";
  }

  return (
    <a
      className={classNames(styles.logoContainer, {
        [styles.mikaHeaderLogo]: site === "mika",
        [styles.ninHeaderLogo]: site === "ny-i-norge",
        [styles.imdiHeaderLogo]: site === "imdi",
        [styles.noraHeaderLogo]: site === "nora",
        [styles.hasDarkBackground]:
          ["green", "dark"].includes(theme) && !isOpen,
      })}
      href={currentLanguageCode ? `/${currentLanguageCode}` : "/"}
      aria-label={ariaLinkLabel}
    >
      {site !== "imdi" ? (
        <>
          <Icon className={styles.siteLogo} name={logoIconName} />
          <span className={styles.divider} />
          <Icon className={styles.imdiLogo} name="imdi-logo-v2" />
          <Icon className={styles.imdiLogoMobile} name="mobile-imdi-logo" />
        </>
      ) : (
        <ImdiLogo />
      )}
    </a>
  );
};

export default HeaderLogo;
