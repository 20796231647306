import React from "react";
import { ReactComponentPropsBase } from "../../base-props/ReactComponentPropsBase";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import MIKAStartPageHeroBlock, {
  MIKAStartPageHeroBlockProps,
} from "mika/blocks/MIKAStartPageHeroBlock/MIKAStartPageHeroBlock";

export interface MIKAStartPageProps extends ReactComponentPropsBase {
  hero: MIKAStartPageHeroBlockProps;
  contentArea: ContentAreaProps;
}

const MIKAStartPage: React.FC<MIKAStartPageProps> = ({ hero, contentArea }) => {
  return (
    <main>
      {hero && <MIKAStartPageHeroBlock {...hero} />}
      {contentArea && <ContentArea {...contentArea} />}
    </main>
  );
};
export default MIKAStartPage;
