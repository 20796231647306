import React from "react";
import styles from "./NotFoundPage.module.scss";
import { ReactComponentPropsBase } from "../../base-props/ReactComponentPropsBase";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import { Icon } from "SiteComponents";
import CTA, { CTAProps } from "components/base/CTA/CTA";
import { useSite } from "src/utils/useSite";

export interface NotFoundPageProps extends ReactComponentPropsBase {
  heading: string;
  message: string;
  primaryLink: CTAProps;
  secondaryLink?: CTAProps;
  statusCode: string;
  statusText?: string;
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({
  heading,
  message,
  primaryLink,
  secondaryLink,
  statusCode,
  statusText,
}) => {
  const site = useSite();

  return (
    <main className={styles.errorPage}>
      <BlockContainer className={styles.errorPageBlock} verticalPadding>
        <Icon
          className={styles.errorPageBlockImage}
          name={`${statusCode}_${site}`}
        />
        <div className={styles.errorPageBlockText}>
          {statusText && <p className={styles.statusText}>{statusText}</p>}
          <h1>{heading}</h1>
          <p>{message}</p>
          <div className={styles.buttonContainer}>
            <CTA size="small" {...primaryLink} />
            {secondaryLink && (
              <CTA theme="secondary" size="small" {...secondaryLink} />
            )}
          </div>
        </div>
      </BlockContainer>
    </main>
  );
};

export default NotFoundPage;
