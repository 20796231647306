import { ReactComponentPropsBase } from "src/base-props/ReactComponentPropsBase";
import styles from "./ContentAnchors.module.scss";
import {
  Accordion,
  AccordionButton,
  AccordionPanel,
} from "components/utils/Accordion/Accordion";
import IconMS from "../../utils/IconMS/IconMS";
import useEscape from "../../../utils/useEscape";
import classNames from "classnames";
import { MouseEvent, useEffect, useRef, useState } from "react";
import ContentAnchorsHeading from "./ContentAnchorHeading";

interface AnchorLink {
  text?: string | null;
  url?: string;
}

interface AnchorLinks extends AnchorLink {
  mainLink: AnchorLink;
  subLinks: AnchorLink[];
}

interface ScrollEvent {
  (event: MouseEvent, targetId: string | undefined): void;
}

export interface ContentAnchorsProps extends ReactComponentPropsBase {
  heading?: string;
}

const ContentAnchors: React.FC<ContentAnchorsProps> = ({
  heading = "På denne siden",
}) => {
  const [linkList, setLinkList] = useState<AnchorLinks[]>([]);
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [previousOffsetHeight, setPreviousOffsetHeight] = useState(0);
  const [hasScrolledPast, setHasScrolledPast] = useState(false);
  const [shouldClose, setShouldClose] = useState(false);
  const navContainerRef = useRef<HTMLDivElement>(null);
  const accordionButtonRef = useRef<HTMLButtonElement>(null);
  const isOpenRef = useRef(false);

  useEffect(() => {
    const extractedLinkList = generateLinkList();
    setLinkList(extractedLinkList);
  }, []);

  useEffect(() => {
    const handleResize = (event: CustomEvent<{ headerHeight: number }>) => {
      setOffsetHeight(prev => {
        setPreviousOffsetHeight(prev);
        return event?.detail?.headerHeight;
      });
    };

    document.addEventListener("headerResize", handleResize);

    return () => {
      document.removeEventListener("headerResize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!navContainerRef.current) return;
      const rect = navContainerRef.current.getBoundingClientRect();
      const height = navContainerRef.current.offsetHeight;
      const isAboveViewport = rect.top + (isOpenRef.current ? height : 0) < 0;

      if (isAboveViewport && !hasScrolledPast) {
        setShouldClose(true);
        setHasScrolledPast(true);
      } else if (rect.top > offsetHeight && hasScrolledPast) {
        setHasScrolledPast(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [offsetHeight]);

  const handleClose = () => {
    setShouldClose(true);
  };

  const handleScroll: ScrollEvent = (event, targetId) => {
    event.preventDefault();
    handleClose();
    if (!targetId) return;
    const distanceToTop = (() => {
      const Id = targetId.substring(1);
      const target = document.getElementById(Id) as HTMLElement;
      const rect = target.getBoundingClientRect();
      const scrollTop = document.documentElement.scrollTop;

      // The menu can be open and taking up height that will be removed when the menu automatically closes as it scrolls to the target
      // This will cause the target to be scrolled past the top of the viewport if we don't account for the height of the menu
      const isOpenAndRelative = isOpenRef.current && !hasScrolledPast;
      const menuHeight = navContainerRef.current?.clientHeight || 0;

      return rect.top + scrollTop - (isOpenAndRelative ? menuHeight : 0);
    })();

    const offsetDistance =
      previousOffsetHeight + (accordionButtonRef.current?.clientHeight || 0);

    window.scrollTo({
      top: distanceToTop - offsetDistance,
      behavior: "smooth",
    });
  };

  useEscape(handleClose);

  return (
    <div ref={navContainerRef}>
      <nav
        className={classNames(styles.contentAnchors, {
          [styles.fixed]: hasScrolledPast,
        })}
        style={{
          maxHeight: hasScrolledPast
            ? `calc(100vh - ${offsetHeight}px)`
            : "unset",
          top: hasScrolledPast ? `${offsetHeight}px` : "0",
          width: hasScrolledPast
            ? `${navContainerRef.current?.offsetWidth}px`
            : "unset",
        }}
        id="content-anchor"
      >
        <Accordion isGlobal>
          <AccordionButton
            className={classNames(styles.header)}
            buttonRef={accordionButtonRef}
            {...{ isGlobal: true }}
          >
            {(isOpen, toggleAccordion) => {
              isOpenRef.current = isOpen;
              return (
                <ContentAnchorsHeading
                  heading={heading}
                  isOpen={isOpen}
                  shouldClose={shouldClose}
                  setShouldClose={setShouldClose}
                  toggleAccordion={toggleAccordion}
                />
              );
            }}
          </AccordionButton>

          <AccordionPanel className={styles.panel} id={heading}>
            <ul className={styles.linkList}>
              {linkList.map(({ mainLink, subLinks }) =>
                mainLink.url ? (
                  <li className={styles.item} key={mainLink.url}>
                    {mainLink.url && (
                      <a
                        href="#"
                        className={styles.itemLink}
                        onClick={event => handleScroll(event, mainLink.url)}
                      >
                        {mainLink.text}
                      </a>
                    )}
                    {subLinks && subLinks.length > 0 && (
                      <ul className={styles.subLinkList}>
                        <SubLinks
                          subLinks={subLinks}
                          handleScroll={handleScroll}
                        />
                      </ul>
                    )}
                  </li>
                ) : (
                  <SubLinks subLinks={subLinks} handleScroll={handleScroll} />
                )
              )}
            </ul>
          </AccordionPanel>
        </Accordion>
      </nav>
    </div>
  );
};

const SubLinks: React.FC<{
  subLinks: AnchorLink[];
  handleScroll: ScrollEvent;
}> = ({ subLinks, handleScroll }) => {
  return (
    <ul>
      {subLinks.map((subLink, subIndex) => (
        <li className={styles.subListItem} key={(subLink.url || "") + subIndex}>
          <IconMS className={styles.iconArrow} name="arrow_right" size="20px" />
          {subLink?.url && (
            <a href="#" onClick={event => handleScroll(event, subLink.url)}>
              {subLink.text}
            </a>
          )}
        </li>
      ))}
    </ul>
  );
};

function generateLinkList() {
  // get all siblings beneath the content-anchor
  const contentAreaBlocks = (document.querySelector("#page-level-content-area")
    ?.childNodes || []) as NodeListOf<HTMLElement>;

  const blocks = [...contentAreaBlocks]; // Convert from nodelist to array
  const extractedLinkList = blocks
    .map((block, i) => {
      const isContentAnchor = !!block?.querySelector("#content-anchor");
      if (isContentAnchor) return {} as AnchorLinks;
      return generateAnchorGroup(block);
    })
    .flatMap(anchorGroup => anchorGroup)
    .filter(
      ({ mainLink, subLinks }) => mainLink?.url || subLinks?.length
    ) as AnchorLinks[];

  return extractedLinkList;
}

function generateAnchorGroup(block: HTMLElement): AnchorLinks[] {
  const headings = block.querySelectorAll("h2, h3");
  if (!headings.length)
    return [
      {
        mainLink: {},
        subLinks: [],
      },
    ];

  return generateAnchorGroupList([...headings]);

  function generateAnchorGroupList(
    headings: any[],
    anchorGroups: AnchorLinks[] = [
      {
        mainLink: {},
        subLinks: [],
      },
    ],
    anchorGroupIndex = 0,
    headingIndex = 0
  ): AnchorLinks[] {
    return generateAnchorGroup(headingIndex);

    function generateAnchorGroup(
      headingIndex: number,
      hasPreviousH2 = false
    ): AnchorLinks[] {
      if (
        !headings[headingIndex] ||
        headings.length < headingIndex ||
        headingIndex > 30
      ) {
        return anchorGroups;
      }
      const heading = headings[headingIndex];
      const isH2 = heading.tagName === "H2";

      if (isH2) {
        if (hasPreviousH2) {
          return generateAnchorGroupList(
            headings,
            [...anchorGroups, { mainLink: {}, subLinks: [] }],
            anchorGroupIndex + 1,
            headingIndex
          );
        }

        anchorGroups[anchorGroupIndex].mainLink = extractLink(heading);
      } else {
        anchorGroups[anchorGroupIndex].subLinks.push(extractLink(heading));
      }
      return generateAnchorGroup(headingIndex + 1, isH2 || hasPreviousH2);
    }
  }

  function extractLink(node: HTMLElement): AnchorLink {
    if (!node) return {};

    const id = Math.random().toString(36).substring(4);
    node.id = id;

    return {
      text: node.textContent,
      url: `#${id}`,
    };
  }
}

export default ContentAnchors;
