import classNames from "classnames";
import styles from "./ImageBlock.module.scss";
import Image, { ImageProps } from "components/base/Image/Image";
export interface ImageBlockProps {
  byline?: string;
  description?: string;
  image: ImageProps;
  theme?: "landscape" | "portrait";
}

const ImageBlock: React.FC<ImageBlockProps> = ({
  byline,
  description,
  image,
  theme = "landscape",
}) => {
  return (
    <div
      className={classNames(styles.imageBlockContainer, "imageBlockContainer")}
    >
      <div
        className={classNames(styles.imageBlock, {
          [styles.landscape]: theme === "landscape",
          [styles.portrait]: theme === "portrait",
        })}
      >
        {image && (
          <Image
            {...image}
            sizes={{
              sm: 350,
              md: 430,
              lg: 450,
              xl: 650,
            }}
          />
        )}
        <div className={classNames(styles.imageText, "imageText")}>
          {description && <p>{description}</p>}
          {byline && <p>{byline}</p>}
        </div>
      </div>
    </div>
  );
};

export default ImageBlock;
