import { useTheme } from "../useTheme";
import "./useHeroBackground.scss";

const useHeroBackground = () => {
  const theme = useTheme();
  let className = "heroBackground";
  if (["dark", "light", "green", "white"].includes(theme)) {
    className += `--${theme}`;
  }
  return className;
};

export default useHeroBackground;
