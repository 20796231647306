import React from "react";
import Video, { VideoProps } from "components/shared/Video/Video";
import styles from "./ArticleHeroBlock.module.scss";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import Image, { ImageProps } from "../../../components/base/Image/Image";
import classNames from "classnames";

export interface ArticleHeroBlockProps extends ReactComponentPropsBase {
  heading: string;
  image?: ImageProps;
  videoLink?: VideoProps;
  publishDate?: string;
}

const ArticleHeroBlock: React.FC<ArticleHeroBlockProps> = ({
  heading,
  image,
  videoLink: video,
  publishDate,
}) => {
  const isWithoutMedia = !image && !video;

  return (
    <BlockContainer className={classNames(styles.articleHeroBlock)}>
      <div className={styles.textContent}>
        <h1 className={styles.heading}>{heading}</h1>
        {publishDate && (
          <div className={styles.dateContainer}>
            <p>{publishDate}</p>
          </div>
        )}
      </div>

      {!isWithoutMedia && (
        <div className={styles.mediaContent}>
          {video && <Video {...video} />}
          {image && !video && (
            <Image
              {...image}
              ratio="hero"
              sizes={{
                sm: 430,
                md: 720,
                lg: 926,
                xl: 1320,
              }}
            />
          )}
        </div>
      )}
    </BlockContainer>
  );
};

export default ArticleHeroBlock;
