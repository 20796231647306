import Image from "components/base/Image/Image";
import { FC, useEffect, useState } from "react";
import { Spinner } from "SiteComponents";

interface VideoThumbnailProps {
  url: string;
}

function getYouTubeThumbnail(url: string) {
  const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
  const match = url.match(regExp);

  const videoId = match && match[2].length === 11 ? match[2] : null;
  if (!videoId) {
    console.error("Unable to extract Video ID from YouTube URL");
    return "";
  }

  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
}

async function getVimeoThumbnail(url: string): Promise<string> {
  const response = await fetch(`https://vimeo.com/api/oembed.json?url=${url}`);
  const data = await response.json();

  const width = window.innerWidth < 768 ? 640 : 1280;
  const urlWithCorrectSize = data.thumbnail_url.replace(/\d*x\d*/g, width);
  return urlWithCorrectSize;
}

const VideoThumbnail: FC<VideoThumbnailProps> = ({ url }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const thumbnailUrl = url.includes("youtube")
      ? getYouTubeThumbnail(url)
      : getVimeoThumbnail(url);

    if (thumbnailUrl instanceof Promise) {
      thumbnailUrl.then(url => {
        setThumbnailUrl(url);
      });
    } else {
      setThumbnailUrl(thumbnailUrl);
    }

    setIsLoading(false);
  }, [url]);

  if (!thumbnailUrl) return null;
  if (isLoading) {
    return <Spinner />;
  }

  return <Image src={thumbnailUrl} alt="Video Thumbnail" />;
};

export default VideoThumbnail;
