import Header, { HeaderProps } from "components/layout/Header/Header";
import Footer, { FooterProps } from "components/layout/Footer/Footer";
import styles from "./Layout.module.scss";
import * as Components from "../../../NyINorge.Components";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import Breadcrumbs, {
  BreadcrumbsProps,
} from "components/layout/Breadcrumbs/Breadcrumbs";
import { SiteContext } from "../../../utils/useSite";
import { ThemeContext } from "../../../utils/useTheme";
import { AlertBannerProps } from "components/layout/AlertBanner/AlertBanner";
import { BypassBannerProps } from "components/layout/BypassBanner/BypassBanner";
import "../../../styles/_reset.scss";
import classNames from "classnames";
import "./Layout.css";

export interface NyINorgeComponent extends ReactComponentPropsBase {
  componentName: keyof typeof Components;
  component: IReactComponentProps;
}
export interface LayoutProps extends ReactComponentPropsBase {
  alertBanner?: AlertBannerProps;
  bypassBanner?: BypassBannerProps;
  header?: HeaderProps;
  footer?: FooterProps;
  component: NyINorgeComponent;
  epiFullRefreshPropertyNames?: string[];
  breadcrumbs?: BreadcrumbsProps;
  theme?: "gray" | "white" | "dark";
}

export interface IReactComponentProps extends IReactProps {}

export interface IReactProps {}

export interface ReactPropsBase {}

export const Layout = ({
  alertBanner,
  bypassBanner,
  header,
  footer,
  component,
  epiFullRefreshPropertyNames,
  breadcrumbs,
  theme,
}: LayoutProps) => {
  const Comp = Components[component.componentName];

  return (
    <SiteContext.Provider value="ny-i-norge">
      <ThemeContext.Provider value={theme ? theme : "white"}>
        <div
          className={classNames(styles.layout, {
            [styles.gray]: theme === "gray",
          })}
        >
          <div className="block-display">
            <div className={styles.headerContainer}>
              {(header || alertBanner) && (
                <Header
                  {...(header ? header : {})}
                  alertBanner={alertBanner}
                  hasBreadcrumbs={breadcrumbs ? true : false}
                  bypassBanner={bypassBanner}
                  layoutTheme={theme}
                />
              )}
              {breadcrumbs && <Breadcrumbs {...breadcrumbs} />}
            </div>

            {Comp && (
              <div id="page-content">
                {/* @ts-ignore */}
                <Comp {...component.component} />
              </div>
            )}
          </div>
          {footer && <Footer {...footer} />}
          {epiFullRefreshPropertyNames && (
            <input
              type="hidden"
              data-epi-full-refresh-property-names={epiFullRefreshPropertyNames?.join(
                ","
              )}
            />
          )}
        </div>
      </ThemeContext.Provider>
    </SiteContext.Provider>
  );
};

export default Layout;
