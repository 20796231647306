import React, { useEffect, useRef, useState } from "react";
import styles from "./MangfoldsTestSlideQuestion.module.scss";
import { ReactComponentPropsBase } from "src/base-props/ReactComponentPropsBase";
import AnimateIn from "components/utils/AnimateIn/AnimateIn";
import Icon from "components/utils/Icon/Icon";

export interface MangfoldsTestSlideQuestionProps
  extends ReactComponentPropsBase {
  userAnswer?: string | null;
  question: string;
  answers: {
    answer: string;
    type: string;
    weight: number;
    response: {
      text: string;
      buttonText?: string;
    };
  }[];
  handleAnswer?: (answer: string) => void;
}

const MangfoldsTestSlideQuestion: React.FC<MangfoldsTestSlideQuestionProps> = ({
  question,
  answers,
  handleAnswer,
}) => {
  const [hasScroll, setHasScroll] = useState(false);
  const focusStartPoint = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (focusStartPoint && focusStartPoint.current) {
      focusStartPoint.current.focus();
    }
  }, []);

  useEffect(() => {
    const checkScroll = () => {
      const main = document.getElementById("mangfoldstesten-main");
      if (!main) return;
      setHasScroll(main.scrollHeight > main.clientHeight);
    };

    checkScroll();

    window.addEventListener("resize", checkScroll); // check on window resize
    return () => {
      window.removeEventListener("resize", checkScroll);
    };
  }, []);

  return (
    <div className={styles.mangfoldsTestSlideQuestion}>
      <AnimateIn className={styles.question}>
        <p ref={focusStartPoint} tabIndex={-1}>
          {question}
        </p>
        {hasScroll && <Icon name="triangle-down" />}
      </AnimateIn>
      {answers && (
        <ul className={styles.answerContainer}>
          {answers.map((answer, i) => (
            <li>
              <AnimateIn className={styles.answer} delay={0.15 * i + 0.2}>
                <button
                  key={answer.answer}
                  onClick={() => handleAnswer && handleAnswer(answer.type)}
                >
                  <Icon name="arrow-right" className={styles.arrow} />
                  <span className={styles.text}> {answer.answer}</span>
                </button>
              </AnimateIn>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MangfoldsTestSlideQuestion;
