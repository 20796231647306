import styles from "./GlobalLinkListBlock.module.scss";
import classNames from "classnames";
import IconMS from "../../utils/IconMS/IconMS";
import Tag from "components/base/Tag/Tag";
import { LinkProps } from "src/base-props/LinkProps";

export interface GlobalLinkListBlockProps {
  heading: string;
  linkListItems: LinkProps[];
}

export const GlobalLinkListBlock: React.FC<GlobalLinkListBlockProps> = ({
  heading,
  linkListItems,
}) => {
  if (!linkListItems) return null;

  const hasDescriptions = linkListItems.some(
    link => link.description !== undefined
  );
  return (
    <div className={classNames(styles.globalLinkListBlock)}>
      {heading && <h2>{heading}</h2>}
      <ul className={styles.listContainer}>
        {linkListItems?.map(link => (
          <a href={link.url} target={link.target}>
            <li
              className={hasDescriptions ? styles.withDescription : undefined}
              key={link.text}
            >
              <div className={styles.linkInfo}>
                <span className={styles.text}>{link.text}</span>
                {link.description && (
                  <span className={styles.description}>{link.description}</span>
                )}
                {link.externalLinkDomain && (
                  <Tag text={link.externalLinkDomain} />
                )}
              </div>
              <IconMS
                className={styles.linkIcon}
                size="24px"
                name={link.externalLinkDomain ? "open_in_new" : "east"}
              />
            </li>
          </a>
        ))}
      </ul>
    </div>
  );
};

export default GlobalLinkListBlock;
