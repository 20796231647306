import styles from "./Media.module.scss";
import classNames from "classnames";

import { ImageProps } from "components/base/Image/Image";
import { VideoProps } from "components/shared/Video/Video";
import Image from "components/base/Image/Image";
import Video from "components/shared/Video/Video";

export type MediaProps = {
  video?: VideoProps;
  image?: ImageProps;
  className?: string;
};

export const Media: React.FC<MediaProps> = ({ className, video, image }) => {
  return (
    <div className={classNames(styles.media, className)}>
      {video && video.url && <Video {...video} image={image} />}
      {image && (!video || !video.url) && <Image {...image} />}
    </div>
  );
};

export default Media;
