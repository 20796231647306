import React from "react";
import { ReactComponentPropsBase } from "../../base-props/ReactComponentPropsBase";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import styles from "./ArticlePage.module.scss";
import RichTextBlock from "components/blocks/RichTextBlock/RichTextBlock";
import ArticleHeroBlock from "mika/blocks/ArticleHeroBlock/ArticleHeroBlock";
import { ArticleHeroBlockProps } from "mika/blocks/ArticleHeroBlock/ArticleHeroBlock";
import useSearchTermHighlight from "src/utils/useHighlightSearchTerm";
import { useTheme } from "src/utils/useTheme";

export interface ArticlePageProps extends ReactComponentPropsBase {
  hero?: ArticleHeroBlockProps;
  contentArea: ContentAreaProps;
  mainText?: ContentAreaProps;
  publishDate?: string;
  mainIntro?: string;
}

const MIKAArticlePage: React.FC<ArticlePageProps> = ({
  contentArea,
  hero,
  publishDate,
  mainText,
  mainIntro,
}) => {
  const theme = useTheme();
  useSearchTermHighlight("article-page", theme);

  return (
    <main className={styles.articlePage} id="article-page">
      {hero && <ArticleHeroBlock {...hero} publishDate={publishDate} />}
      {(mainIntro || (mainText && mainText.items.length > 0)) && (
        <BlockContainer className={styles.blockContainer}>
          <div className={styles.contentContainer}>
            {mainIntro && <div className={styles.mainIntro}>{mainIntro}</div>}
            {mainText && (
              <div className={styles.mainText}>
                <RichTextBlock text={mainText} />
              </div>
            )}
          </div>
        </BlockContainer>
      )}
      {contentArea && <ContentArea {...contentArea} />}
    </main>
  );
};

export default MIKAArticlePage;
