import { FC, useContext, ReactNode } from "react";
import { Collapse } from "react-collapse";
import { AccordionContext } from "./Accordion";
import styles from "./Accordion.module.scss";
import classNames from "classnames";

interface AccordionPanelProps {
  children: ReactNode;
  className?: string;
  id?: string;
}

const AccordionPanel: FC<AccordionPanelProps> = ({
  children,
  className,
  id,
}) => {
  const { setIsClosed, isClosed, isOpen } = useContext(AccordionContext)!;

  return (
    <Collapse
      role="region"
      theme={{
        collapse: classNames(styles.collapse, { [styles.isClosed]: isClosed }),
        content: className,
      }}
      isOpened={isOpen}
      onRest={({ isFullyClosed }: { isFullyClosed: any }) =>
        setIsClosed(isFullyClosed)
      }
    >
      <div data-accordion-content id={id}>
        {children}
      </div>
    </Collapse>
  );
};
export default AccordionPanel;
