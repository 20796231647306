import classNames from "classnames";
import styles from "./FeaturedArticlesBlock.module.scss";
import FeaturedArticlesCard, {
  FeaturedArticlesCardProps,
} from "./FeaturedArticlesCard";
import BlockContainer from "../../../components/layout/BlockContainer/BlockContainer";

export interface FeaturedArticlesBlockProps {
  featuredArticles: FeaturedArticlesCardProps[];
  heading: string;
  theme?: "gray" | "green" | "white";
}

const FeaturedArticlesBlock: React.FC<FeaturedArticlesBlockProps> = ({
  featuredArticles,
  heading,
  theme,
}) => {
  const isWhiteTheme = theme === "white";
  return (
    <div
      className={classNames(
        styles.featuredArticlesBlock,
        theme ? styles[theme] : null
      )}
    >
      <BlockContainer verticalPadding={!isWhiteTheme}>
        <h2>{heading}</h2>
        {featuredArticles?.length > 0 && (
          <div
            className={classNames(styles.cardContainer, {
              [styles.hasThree]: featuredArticles.length === 3,
            })}
          >
            {featuredArticles.map((card, index) => (
              <FeaturedArticlesCard key={index} {...card} cardTheme={theme} />
            ))}
          </div>
        )}
      </BlockContainer>
    </div>
  );
};

export default FeaturedArticlesBlock;
