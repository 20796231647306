import useWindowSize from "src/utils/useWindowSize";
import { BlockContainer } from "../../../MIKA.Components";
import styles from "./ResultHeroBlock.module.scss";
import { Icon } from "SiteComponents";

export interface ResultHeroBlockProps {
  pageName: string;
  link?: {
    prefix: string;
    label: string;
  };
  title?: string;
  description?: string;
}

const ResultHeroBlock: React.FC<ResultHeroBlockProps> = ({
  pageName,
  link,
  title,
  description,
}) => {
  const isDesktop = useWindowSize().width > 768;

  return (
    <div className={styles.resultHeroBlock}>
      <BlockContainer className={styles.blockContainer}>
        <div className={styles.header}>
          <div className={styles.pageName}>{pageName}</div>
          {link && (
            <a className={styles.link} href={"/"} aria-label={link.label}>
              <span>{link.prefix}</span>
              <Icon className={styles.logo} name="imdi-logo" />
            </a>
          )}
        </div>
        <div className={styles.content}>
          <Icon
            className={styles.illustration}
            name={
              isDesktop
                ? "mangfoldstesten/mangfoldstesten-desktop"
                : "mangfoldstesten/mangfoldstesten-mobile"
            }
          />
          {title && <h1>{title}</h1>}
          {description && <p>{description}</p>}
        </div>
      </BlockContainer>
    </div>
  );
};

export default ResultHeroBlock;
