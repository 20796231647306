import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./Tabs.module.scss";
import Tab, { TabProps } from "./Tab";
import Panel from "./Panel";
import { Dropdown, Spinner } from "SiteComponents";
import Sort, { SortProps } from "./Sort";

export interface TabsProps {
  defaultActiveTab?: string;
  size?: "small" | "medium" | "large";
  type?: "primary" | "secondary";
  tabList: TabProps[];
  PanelComponent?: React.FC<any>; // FE only
  panelComponentProps?: any; // FE only
  onTabChange?: (id: string) => void;
  onSortChange?: (id: string) => void;
  isLoading?: boolean;
  sortOptions: SortProps;
}

export const Tabs = ({
  defaultActiveTab,
  tabList,
  sortOptions,
  PanelComponent,
  panelComponentProps,
  size,
  type,
  onTabChange,
  onSortChange,
  isLoading,
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab || "");

  useEffect(() => {
    if (onTabChange && activeTab) {
      onTabChange(activeTab);
    }
  }, [activeTab]);

  return (
    <div className={styles.tabs}>
      {tabList && (
        <ul className={styles.list}>
          {tabList.map(tab => (
            <Tab
              key={tab.id}
              {...tab}
              size={size}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          ))}
          {sortOptions && (
            <li className={styles.sortContainer}>
              <Sort {...sortOptions} onChange={onSortChange} />
            </li>
          )}
        </ul>
      )}
      <div
        className={classNames(styles.panel, {
          [styles[size || "medium"]]: size,
          [styles[type || "primary"]]: type,
        })}
      >
        {PanelComponent && panelComponentProps && (
          <Panel id={activeTab} activeTab={activeTab}>
            <>
              {isLoading && <Spinner />}
              <PanelComponent {...panelComponentProps} isInsideTabs />
            </>
          </Panel>
        )}
      </div>
    </div>
  );
};

export default Tabs;
