import React from "react";
import styles from "./FilterList.module.scss";
import Checkbox, { CheckboxProps } from "../../../../base/Checkbox/Checkbox";

export interface Filter extends CheckboxProps {
  nestedFilters?: Filter[];
}

interface FilterListProps {
  filters: Filter[];
  handleFilterChange: (id: string, checked: boolean) => void;
}

const FilterList: React.FC<FilterListProps> = ({
  filters,
  handleFilterChange,
}) => {
  if (!filters?.length) return null;
  return (
    <ul className={styles.filterList}>
      {filters.map(({ label, id, hitCount, checked, nestedFilters }) => (
        <li key={id}>
          <Checkbox
            label={label}
            id={id}
            hitCount={hitCount}
            value={id}
            checked={checked}
            handleChange={handleFilterChange}
          />
          {nestedFilters && (
            <FilterList
              filters={nestedFilters}
              handleFilterChange={handleFilterChange}
            />
          )}
        </li>
      ))}
    </ul>
  );
};

export default FilterList;
