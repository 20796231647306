import React from "react";
import styles from "./Sort.module.scss";
import { Dropdown } from "SiteComponents";
import { DropdownProps } from "components/base/Dropdown/Dropdown";

export interface SortProps extends DropdownProps {
  optionList: { name: string; value: string }[];
  onChange?: (id: string) => void;
}

export const Sort = ({
  buttonLabel,
  labels,
  optionList,
  onChange = () => {},
}: SortProps) => {
  return (
    <div className={styles.sort}>
      <Dropdown buttonLabel={buttonLabel} labels={labels}>
        {optionList.map(option => (
          <li key={option.name} role="menuitem">
            <button
              onClick={() => onChange(option.value)}
              className={styles.option}
            >
              {option.name}
            </button>
          </li>
        ))}
      </Dropdown>
    </div>
  );
};

export default Sort;
