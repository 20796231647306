import classNames from "classnames";
import styles from "./GlobalLayout.module.scss";

import { AlertBannerProps } from "../AlertBanner/AlertBanner";
import Breadcrumbs, { BreadcrumbsProps } from "../Breadcrumbs/Breadcrumbs";
import { BypassBannerProps } from "../BypassBanner/BypassBanner";
import Footer, { FooterProps } from "../Footer/Footer";
import Header, { HeaderProps } from "../Header/Header";
import { IReactComponentProps } from "src/base-props/IReactComponentProps";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import { SiteContext } from "../../../utils/useSite";
import { ThemeContext } from "../../../utils/useTheme";
import * as Components from "../../../App.Components";

export interface SITEComponent extends ReactComponentPropsBase {
  componentName: keyof typeof Components;
  component: IReactComponentProps;
}

export interface GlobalLayoutProps extends ReactComponentPropsBase {
  alertBanner?: AlertBannerProps;
  breadcrumbs?: BreadcrumbsProps;
  bypassBanner?: BypassBannerProps;
  component: SITEComponent;
  epiFullRefreshPropertyNames?: string[];
  footer?: FooterProps;
  header?: HeaderProps;
  siteType?: "mika" | "ny-i-norge" | "imdi" | "inpro" | "nora" | undefined;
  theme?: "white" | "dark";
}

export interface ReactPropsBase {}

export const GlobalLayout = ({
  alertBanner,
  breadcrumbs,
  bypassBanner,
  component,
  epiFullRefreshPropertyNames,
  footer,
  header,
  siteType,
  theme,
}: GlobalLayoutProps) => {
  const Comp = Components[component.componentName] as React.FC<any>;

  return (
    <SiteContext.Provider value={siteType ? siteType : "imdi"}>
      <ThemeContext.Provider value={theme ? theme : "white"}>
        <div
          className={classNames(styles.layout, theme ? styles[theme] : null)}
        >
          <div className="block-display">
            <div className={styles.headerContainer}>
              {header && (
                <Header
                  {...header}
                  alertBanner={alertBanner}
                  bypassBanner={bypassBanner}
                />
              )}
              {breadcrumbs && <Breadcrumbs {...breadcrumbs} />}
            </div>

            {Comp && (
              <div id="page-content">
                <Comp {...component.component} />
              </div>
            )}
          </div>
          {footer && <Footer {...footer} />}
          {epiFullRefreshPropertyNames && (
            <input
              type="hidden"
              data-epi-full-refresh-property-names={epiFullRefreshPropertyNames?.join(
                ","
              )}
            />
          )}
        </div>
      </ThemeContext.Provider>
    </SiteContext.Provider>
  );
};

export default GlobalLayout;
