import { useCallback, useEffect, useRef, useState } from "react";
import { setTabIndexes } from "./setTabIndexes";

/*
 * This hook is used to expand and collapse a section of text
 * when a button is clicked. It also sets the tabindex of links in the
 * text to -1 when collapsed and 0 when expanded.
 */
const useExpand = (
  onExpand?: (expandedHeight: number | undefined) => void,
  onClose?: (minimizedHeight: number | undefined) => void
) => {
  const [expandedHeight, setExpandedHeight] = useState<undefined | number>(
    undefined
  );
  const [minimizedHeight, setMinimizedHeight] = useState<undefined | number>(
    undefined
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const expandNodeRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const node = expandNodeRef.current;
    if (!node) return;
    if (isExpanded) {
      setTabIndexes(node, "0");
      onExpand && onExpand(expandedHeight);

      const links = node.querySelectorAll("a");
      if (links.length > 0) {
        links[0].focus();
      }
    } else {
      onClose && onClose(minimizedHeight);
      setTabIndexes(node, "-1");
    }
  }, [isExpanded]);

  const textRefFunction = useCallback((node: HTMLDivElement | null) => {
    // Only executes when the reffed node's initially renders.
    if (!node || expandNodeRef.current) return;
    expandNodeRef.current = node;
    const scrollHeight = node.scrollHeight || 0;
    const offsetHeight = node.offsetHeight || 0;
    setTabIndexes(node, "-1");

    setMinimizedHeight(offsetHeight);
    setExpandedHeight(scrollHeight);
  }, []);

  return {
    minimizedHeight,
    expandedHeight,
    textRefFunction,
    expandNodeRef,
    isExpanded,
    setIsExpanded,
  };
};

export default useExpand;
