import React, { FC } from "react";
import styles from "./Spinner.module.scss";
import classNames from "classnames";
import { useTheme } from "src/utils/useTheme";

interface SpinnerProps {
  className?: string;
  size?: "sm" | "md" | "lg" | "xl";
}
const Spinner: FC<SpinnerProps> = ({ className, size = "xl" }) => {
  const theme = useTheme();

  return (
    <div className={classNames(styles.spinnerOverlay, className)}>
      <div
        className={classNames(styles.spinner, {
          [styles.dark]: theme === "dark",
          [styles[size]]: size,
        })}
      ></div>
    </div>
  );
};

export default Spinner;
