import React from "react";
import styles from "./EventPageHeroBlock.module.scss";
import classNames from "classnames";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import IconMS from "src/components/utils/IconMS/IconMS";
import useHeroBackground from "src/utils/use-hero-background/useHeroBackground";
import { useTheme } from "src/utils/useTheme";
import { LinkProps } from "src/base-props/LinkProps";
import { CTA, SimpleLink } from "SiteComponents";

export interface EventPageHeroBlockProps extends ReactComponentPropsBase {
  heading: string;
  tags: {
    place?: string;
    type?: string;
  };
  lastUpdated: string;
  signOn: {
    link?: LinkProps;
    unavailableMessage?: {
      heading: string;
      text?: string;
      link?: LinkProps;
    };
  };
  location: {
    time: string;
    text?: string;
    link?: LinkProps;
    type: "physical" | "digital";
  };
}

const EventPageHeroBlock: React.FC<EventPageHeroBlockProps> = ({
  heading,
  tags,
  lastUpdated,
  signOn,
  location,
}) => {
  const backgroundClass = useHeroBackground();
  const theme = useTheme();

  return (
    <div className={backgroundClass}>
      <BlockContainer
        className={classNames(styles.eventPageHeroBlock, {
          [styles.dark]: theme === "dark",
        })}
        verticalPadding
      >
        {tags && (
          <div className={styles.tagsContainer}>
            {tags.place && <div className={styles.place}>{tags.place}</div>}
            {tags.type && <div className={styles.type}>{tags.type}</div>}
          </div>
        )}
        {heading && <h1>{heading}</h1>}

        {(signOn || location) && (
          <div className={styles.actions}>
            {signOn.link && (
              <div className={styles.signOn}>
                <CTA {...signOn.link} />
              </div>
            )}
            {signOn.unavailableMessage && signOn.unavailableMessage.heading && (
              <div className={styles.unavailableMessage}>
                {signOn.unavailableMessage.heading && (
                  <p className={styles.unavailableHeading}>
                    <strong>{signOn.unavailableMessage.heading}</strong>
                  </p>
                )}
                <p className={styles.unavailableText}>
                  {signOn.unavailableMessage.text}
                </p>
                {signOn.unavailableMessage.link && (
                  <a
                    className={styles.unavailableText}
                    href={signOn.unavailableMessage.link.url}
                  >
                    {signOn.unavailableMessage.link?.text}
                  </a>
                )}
              </div>
            )}
            {location && (
              <div className={styles.location}>
                <IconMS
                  className={styles.icon}
                  name={location.type === "physical" ? "distance" : "mimo"}
                  size={{ xs: "24px", md: "48px" }}
                  weight="300"
                />
                {location.time && (
                  <div className={styles.time}>{location.time}</div>
                )}
                {location.link && (
                  <div className={styles.mapLink}>
                    <SimpleLink {...location.link} isUnderlined />
                  </div>
                )}
                {location.text && (
                  <div className={styles.text}>{location.text}</div>
                )}
              </div>
            )}
          </div>
        )}

        {lastUpdated && (
          <div className={styles.lastUpdated}>
            <IconMS className={styles.icon} name="stylus" size="20px" />
            <p>{lastUpdated}</p>
          </div>
        )}
      </BlockContainer>
    </div>
  );
};

export default EventPageHeroBlock;
