import React, { useState } from "react";
import styles from "./MangfoldstestenPage.module.scss";

import MangfoldsTestBlock, {
  MangfoldsTestBlockProps,
} from "src/components-mika/blocks/MangfoldsTestBlock/MangfoldsTestBlock";

import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import Icon from "components/utils/Icon/Icon";
import { ReactComponentPropsBase } from "src/base-props/ReactComponentPropsBase";
import MangfoldsTestSlideInfo, {
  MangfoldsTestSlideInfoProps,
} from "src/components-mika/blocks/MangfoldsTestBlock/MangfoldsTestSlideInfo/MangfoldsTestSlideInfo";
import useWindowSize from "../../utils/useWindowSize";
import OverrideTheme from "components/utils/OverrideTheme/OverrideTheme";

export interface MangfoldstestenPageProps extends ReactComponentPropsBase {
  mangfoldstesten: MangfoldsTestBlockProps;
  startSlide: MangfoldsTestSlideInfoProps;
  link?: {
    prefix: string;
  };
  imdiLogoLabel?: string;
}

const MangfoldstestenPage: React.FC<MangfoldstestenPageProps> = ({
  mangfoldstesten,
  startSlide,
  link,
  imdiLogoLabel,
}) => {
  const [hasTestStarted, setHasTestStarted] = useState(false);

  const isDesktop = useWindowSize().width > 768;

  return (
    <OverrideTheme theme="dark">
      <main id="mangfoldstesten-main" className={styles.mangfoldsTestPage}>
        <BlockContainer className={styles.blockContainer}>
          {hasTestStarted ? (
            <MangfoldsTestBlock {...mangfoldstesten} />
          ) : (
            <>
              {link && (
                <div className={styles.logoContainer}>
                  <span>{link.prefix}</span>
                  <span className={styles.visuallyHidden}>{imdiLogoLabel}</span>
                  <Icon name="imdi-logo" aria-hidden="true" />
                </div>
              )}
              <MangfoldsTestSlideInfo
                {...startSlide}
                iconNames={
                  isDesktop
                    ? ["mangfoldstesten/mangfoldstesten-desktop"]
                    : ["mangfoldstesten/mangfoldstesten-mobile"]
                }
                onClick={() => setHasTestStarted(true)}
                isStartSlide
              />
            </>
          )}
        </BlockContainer>
      </main>
    </OverrideTheme>
  );
};

export default MangfoldstestenPage;
