import React, { useEffect, useState, RefObject } from "react";
import styles from "./Menu.module.scss";
import classNames from "classnames";
import {
  Accordion,
  AccordionButton,
  AccordionPanel,
} from "components/utils/Accordion/Accordion";
import Dropdown from "components/base/Dropdown/Dropdown";
import BlockContainer from "../BlockContainer/BlockContainer";
import IconMS from "../../utils/IconMS/IconMS";
import useEscape from "../../../utils/useEscape";
import useWindowSize from "src/utils/useWindowSize";
import useClickOutside from "../../../utils/useClickOutside";
import { LinkProps } from "src/base-props/LinkProps";
import useDisableScroll from "src/utils/useDisableScroll";

interface NavigationLinksProps extends LinkProps {
  children?: LinkProps[];
}

interface PromotedLinkProps extends LinkProps {
  icon: string;
}

export interface MenuProps {
  closeMenu?: () => void;
  languages?: {
    availableLanguages: {
      currentPageUrl: string;
      languageCode: string;
      languageName: string;
    }[];
    currentLanguage: string;
  };
  labels?: {
    closeMenuLabel: string;
  };
  loginLink?: LinkProps;
  navigationLinks?: NavigationLinksProps[];
  promotedLinks?: PromotedLinkProps[];
  shouldCloseMenu?: boolean;
  dropdownRef: RefObject<HTMLDivElement>;
  offsetTop?: number;
}

const Menu: React.FC<MenuProps> = ({
  closeMenu,
  languages,
  loginLink,
  navigationLinks,
  promotedLinks,
  shouldCloseMenu,
  dropdownRef,
  offsetTop,
}) => {
  const [shouldAnimateOut, setShouldAnimateOut] = useState(false);
  const [activeDropdownRef, setActiveDropdownRef] = useState<
    RefObject<HTMLDivElement> | undefined
  >();
  const [shouldFadeOut, setShouldFadeOut] = useState(false);

  const mobileDropdownRef = React.useRef<HTMLDivElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);

  const isDesktop = useWindowSize().width > 768;
  useDisableScroll(true);

  const mediumMenu = !navigationLinks || navigationLinks.length === 0;

  const onCloseMenu = () => {
    setShouldAnimateOut(true);
    setShouldFadeOut(true);
    setTimeout(() => {
      closeMenu && closeMenu();
    }, 200);
  };

  useEffect(() => {
    if (shouldCloseMenu) {
      onCloseMenu();
    }
  }, [shouldCloseMenu]);

  useEffect(() => {
    if (isDesktop && !languages) {
      setActiveDropdownRef(undefined);
    } else {
      setActiveDropdownRef(isDesktop ? dropdownRef : mobileDropdownRef);
    }
  }, [isDesktop, languages, dropdownRef, mobileDropdownRef]);

  useClickOutside(
    [menuRef, activeDropdownRef ? activeDropdownRef : { current: undefined }],
    () => onCloseMenu()
  );

  useEscape(onCloseMenu);

  return (
    <>
      {mediumMenu && (
        <div
          aria-hidden="true"
          className={classNames(styles.backgroundOverlay, {
            [styles.fadeOut]: shouldFadeOut,
          })}
        ></div>
      )}
      <nav
        className={classNames(styles.megamenu, {
          [styles.mediumMenu]: mediumMenu,
          [styles.animateOut]: shouldAnimateOut,
        })}
        role="menu"
        ref={menuRef}
        // @ts-ignore: CSS variable
        style={{ "--offsetTop": offsetTop + "px" }}
      >
        <BlockContainer className={styles.megamenuContainer}>
          {!isDesktop && (
            <div className={styles.topLinkContainer}>
              {languages && (
                <div className={styles.languageDropdownContainer}>
                  <IconMS name="language" />
                  <Dropdown
                    buttonLabel={languages.currentLanguage}
                    labels={{
                      closeDropdownLabel: languages.currentLanguage,
                      openDropdownLabel: languages.currentLanguage,
                    }}
                    className={styles.languageDropdown}
                    inHeader={true}
                    dropdownRef={mobileDropdownRef}
                  >
                    {languages.availableLanguages.map(language => (
                      <li key={language.languageName}>
                        <a
                          className={styles.languageText}
                          href={language.currentPageUrl}
                        >
                          {language.languageName}
                        </a>
                      </li>
                    ))}
                  </Dropdown>
                </div>
              )}
              {loginLink && loginLink.url && (
                <div className={styles.loginLink}>
                  <IconMS name="lock_open" />
                  <a target={loginLink?.target} href={loginLink?.url}>
                    <span className={styles.linkText}>{loginLink?.text}</span>
                  </a>
                </div>
              )}
              {languages || loginLink ? (
                <span className={styles.menuDivider} />
              ) : null}
            </div>
          )}
          {navigationLinks && navigationLinks.length > 0 && (
            <ul
              className={classNames(styles.sectionListsContainer, {
                [styles.isMediumMenu]: mediumMenu,
              })}
            >
              {navigationLinks.map(({ text, url, children, target }) => (
                <>
                  {isDesktop ? (
                    <li className={styles.sectionListItem} key={text}>
                      <ul>
                        <li>
                          <a href={url}>
                            <span className={styles.linkText}>{text}</span>
                          </a>
                        </li>

                        {children && children?.length ? (
                          <>
                            {children.map(subLink => (
                              <li
                                className={styles.sectionSublist}
                                key={subLink.text}
                              >
                                <IconMS name="arrow_right" size="20px" />
                                <a href={subLink.url} target={target}>
                                  <span className={styles.linkText}>
                                    {subLink.text}
                                  </span>
                                </a>
                              </li>
                            ))}
                          </>
                        ) : null}
                      </ul>
                    </li>
                  ) : (
                    <li className={styles.accordionListItem} key={text}>
                      {children && children?.length ? (
                        <Accordion className={styles.accordion}>
                          <div className={styles.accordionButtonContainer}>
                            <a href={url} target={target}>
                              <span className={styles.linkText}>{text}</span>
                            </a>
                            <AccordionButton id="menuAccordion">
                              {(isOpen: boolean) => (
                                <IconMS name={isOpen ? "remove" : "add"} />
                              )}
                            </AccordionButton>
                          </div>
                          <AccordionPanel id="menuAccordion">
                            {children && children.length ? (
                              <ul className={styles.accordionSubLinkList}>
                                {children.map(subLink => (
                                  <li key={subLink.text}>
                                    <IconMS
                                      className={styles.linkIcon}
                                      name="arrow_right"
                                    />
                                    <a href={subLink.url}>
                                      <span className={styles.linkText}>
                                        {subLink.text}
                                      </span>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            ) : null}
                          </AccordionPanel>
                        </Accordion>
                      ) : (
                        <a href={url}>
                          <span className={styles.linkText}>{text}</span>
                        </a>
                      )}
                    </li>
                  )}
                </>
              ))}
            </ul>
          )}
          {promotedLinks && promotedLinks.length > 0 && (
            <div className={styles.bottomMenu}>
              {!mediumMenu && <span className={styles.menuDivider} />}
              <ul className={styles.promotedMenuContainer}>
                {promotedLinks.map(
                  ({ text, url, icon, target, externalLinkDomain }) => (
                    <li key={text}>
                      <IconMS className={styles.linkIcon} name={icon} />
                      <a href={url} target={target}>
                        <span className={styles.linkText}>{text}</span>
                        {externalLinkDomain ? (
                          <IconMS name="open_in_new" size="16px" />
                        ) : null}
                      </a>
                    </li>
                  )
                )}
              </ul>
            </div>
          )}
        </BlockContainer>
      </nav>
    </>
  );
};

export default Menu;
