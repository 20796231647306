import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import styles from "./VerticalSectionBlock.module.scss";
import "./VerticalSectionBlock.scss";
import RichTextBlock from "../RichTextBlock/RichTextBlock";

export type VerticalSectionBlockProps = {
  contentArea?: ContentAreaProps;
  text?: ContentAreaProps;
  ingress?: string;
  heading?: string;
};

const VerticalSectionBlock: React.FC<VerticalSectionBlockProps> = ({
  heading,
  ingress,
  text,
  contentArea,
}) => {
  return (
    <div className={styles.verticalSectionBlock}>
      <div className={styles.textContent}>
        {heading && <h2 className={styles.heading}>{heading}</h2>}
        {ingress && contentArea && (
          <div className={styles.ingress}>{ingress}</div>
        )}
      </div>
      <div className={styles.blocks}>
        {text && <RichTextBlock text={text} />}
        {contentArea && (
          <ContentArea
            className={styles.blocksContent}
            {...contentArea}
            isInsideBlock
          />
        )}
        {ingress && !contentArea && !text && (
          <div className={styles.ingress}>{ingress}</div>
        )}
      </div>
    </div>
  );
};

export default VerticalSectionBlock;
